// This file is automatically generated. See gqlcodegen.yml for details
// prettier-ignore
// @ts-ignore
import * as CustomScalars from "@oui/lib/src/types/scalars";
import * as Types from '@oui/lib/src/types/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateOrganizationMutationVariables = Types.Exact<{
  organizationID: Types.Scalars['UUID']['input'];
  organization: Types.OrganizationUpdateInput;
}>;


export type UpdateOrganizationMutation = { updateOrganization: { ID: CustomScalars.GQLUUID, members: Array<{ __typename: 'OrganizationMember', userID: CustomScalars.GQLUUID }> } | null };


export const UpdateOrganizationDocument = gql`
    mutation UpdateOrganization($organizationID: UUID!, $organization: OrganizationUpdateInput!) {
  updateOrganization(organizationID: $organizationID, organization: $organization) {
    ID
    members {
      __typename
      userID
    }
  }
}
    `;

/**
 * __useUpdateOrganizationMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationMutation, { data, loading, error }] = useUpdateOrganizationMutation({
 *   variables: {
 *      organizationID: // value for 'organizationID'
 *      organization: // value for 'organization'
 *   },
 * });
 */
export function useUpdateOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>(UpdateOrganizationDocument, options);
      }
export type UpdateOrganizationMutationHookResult = ReturnType<typeof useUpdateOrganizationMutation>;

export function performUpdateOrganization(client: Apollo.ApolloClient<unknown>, variables: UpdateOrganizationMutationVariables, options?: Omit<Apollo.MutationOptions<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>, "mutation" | 'variables'>) {
  return client.mutate<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>({
    mutation: UpdateOrganizationDocument,
    variables,
    ...options,
  });
}
