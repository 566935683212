'use client';

import Tooltip from '@reach/tooltip';
import { ComponentProps } from 'react';

export function WebTooltip({ children, style, ...rest }: ComponentProps<typeof Tooltip>) {
  return (
    <Tooltip
      {...rest}
      style={{
        background: 'rgba(36, 34, 38, 0.7)',
        borderRadius: '5px',
        color: 'white',
        fontFamily: 'OpenSansRegular',
        fontSize: 13,
        maxWidth: 150,
        padding: '4px 8px',
        textAlign: 'center',
        transform: 'translateX(-40%)',
        whiteSpace: 'initial',
        ...style,
      }}
    >
      <span style={{ display: 'flex' }}>{children}</span>
    </Tooltip>
  );
}
