import { StackActions, useFocusEffect, useNavigation } from '@react-navigation/native';
import * as shape from 'd3-shape';
import { useCallback, useMemo, useState } from 'react';
import { Circle, G, Line } from 'react-native-svg';

import { useAppContext } from '@oui/app-core/src/components/AppContext';
import { LineChart, XAxis } from '@oui/app-core/src/svgCharts';
import { parseGQLDateTime } from '@oui/lib/src/gqlDate';
import { GQLUUID } from '@oui/lib/src/types/scalars';

import { Button } from '@src/components/Button';
import PickerInput from '@src/components/PickerInput';
import { StaffTable, TableCell } from '@src/components/StaffTable';
import { OldHeading, Text } from '@src/components/Text';
import { View } from '@src/components/View';
import {
  Assessment,
  assessmentResultsString,
  isPHQ9,
  useAssessments,
  useCreateAssessment,
} from '@src/hooks/useAssessments';
import { Color } from '@src/styles';
import { CliniciansScreenProps } from '@src/types';

const QUESTIONNAIRE_ITEMS: Array<{ label: string; value: Assessment['type'] }> = [
  { label: 'PHQ-9', value: 'PHQ9' },
  { label: 'C-SSRS', value: 'CSSRS' },
  { label: 'SCS', value: 'SCS' },
  { label: 'B-SCS', value: 'BSCS' },
  { label: 'BSS', value: 'BECK' },
];

const ASSESMENT_ABBREVIATION_BY_TYPE: Record<Assessment['type'], string> = {
  BECK: 'BSS',
  CSSRS: 'C-SSRS',
  PHQ9: 'PHQ',
  SCS: 'SCS',
  BSCS: 'B-SCS',
  UNKNOWN: 'Unknown',
};

const ASSESMENT_NAMES_BY_TYPE: Record<Assessment['type'], string> = {
  BECK: '(Beck Scale for Suicide Ideation)',
  CSSRS: '(Columbia-Suicide Severity Rating Scale)',
  PHQ9: '(Patient Health Questionnaire)',
  SCS: '(Suicide Cognitions Scale)',
  BSCS: '(Brief Suicide Cognitions Scale)',
  UNKNOWN: '',
};

function PHQRatings(props: { data: { rating: number; date: string }[] }) {
  const dateTimeFormatter = new Intl.DateTimeFormat('en-US', {
    month: 'numeric',
    day: 'numeric',
  });

  const entries = props.data.filter((d) => d.date);
  const data = entries.map((d) => d.rating);
  const labels = entries.map((d) => dateTimeFormatter.format(new Date(d.date)));

  const axesSvg = { fontSize: 10, fill: 'grey' };
  const contentInset = { left: 10, right: 10, top: 10, bottom: 10 };
  const xAxisHeight = 30;

  const lines = [27, 24, 21, 18, 15, 12, 9, 6, 3, 0];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const Grid = ({ x, y }: any) => {
    return (
      <G>
        {lines.map((value) =>
          value === 0 ? null : (
            <Line key={value} x1={'0%'} x2={'100%'} y1={y(value)} y2={y(value)} stroke="#efeff4" />
          ),
        )}
      </G>
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const Decorator = ({ x, y }: any) => {
    return (
      <>
        {data.map((value, index) => (
          <Circle
            key={index}
            cx={x(index)}
            cy={y(value)}
            r={4}
            fill={Color.styleGuide.BrandSpaceBlue}
          />
        ))}
      </>
    );
  };

  return (
    <View>
      <Text text="Results" weight="semibold" />
      <View style={{ paddingHorizontal: 5, paddingTop: 10, height: 280, flexDirection: 'row' }}>
        <View style={{ justifyContent: 'space-between', marginBottom: xAxisHeight + 10 }}>
          {lines.map((v) => (
            <Text text={v.toString()} key={v} size={12} color="grey" />
          ))}
        </View>
        <View style={{ flex: 1, marginLeft: 10 }}>
          <LineChart
            yMin={0}
            yMax={27}
            numberOfTicks={9}
            style={{ flex: 1, borderLeftWidth: 1, borderBottomWidth: 1, borderColor: '#c5c5d3' }}
            data={data}
            contentInset={contentInset}
            svg={{ stroke: Color.styleGuide.BrandSpaceBlue, strokeWidth: 2 }}
            curve={shape.curveNatural}
          >
            <Grid belowChart />
            <Decorator />
          </LineChart>
          <XAxis
            style={{ height: xAxisHeight, marginTop: 10 }}
            data={labels}
            formatLabel={(_, index) => labels[index]}
            contentInset={{ left: 10, right: 10 }}
            svg={axesSvg}
          />
        </View>
      </View>
      <Text text="Date taken" weight="semibold" textAlign="center" />
    </View>
  );
}

export function PatientSectionQuestionnaires({ patientID }: { patientID: GQLUUID }) {
  const { navigate } = useNavigation<CliniciansScreenProps<'Patient'>['navigation']>();
  const createAssessment = useCreateAssessment({ patientID });
  const { loading, data, refetch } = useAssessments({ patientID });
  const [newAssessmentType, setNewAssessmentType] = useState<Assessment['type']>('PHQ9');
  const { locale } = useAppContext();

  const phq9s = useMemo(() => {
    return (data ?? [])
      .map((d) => d.assessment)
      .filter(isPHQ9)
      .map((phq) => ({
        rating: phq.responses.reduce((carry, v) => carry + v, 0),
        date: phq.occurrenceDate,
      }))
      .sort((a, b) => {
        if (a.date === b.date) return 0;
        return a.date < b.date ? -1 : 1;
      });
  }, [data]);

  const sortedData = useMemo(() => {
    return data
      ? [...data].sort((a, b) => {
          return (a.assessment.occurrenceDate ?? '') < (b.assessment.occurrenceDate ?? '') ? 1 : -1;
        })
      : undefined;
  }, [data]);

  const dateTimeFormatter = new Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });

  useFocusEffect(
    useCallback(() => {
      refetch();
    }, [refetch]),
  );

  return (
    <View spacing={16}>
      <View
        row
        style={{
          borderRadius: 10,
          backgroundColor: 'white',
          paddingHorizontal: 25,
          paddingVertical: 10,
          justifyContent: 'space-between',
          borderColor: Color.styleGuide.Gray6,
          borderWidth: 1,
        }}
      >
        <Text text="New Questionnaire" size={17} weight="semibold" />
        <PickerInput
          items={QUESTIONNAIRE_ITEMS}
          value={newAssessmentType}
          onChangeValue={setNewAssessmentType}
          testID="PatientSectionQuestionnaires_questionnaireTypeInput"
        />
        <Button
          variant="solid"
          text="Start"
          iconRight="arrow-right"
          onPress={() => {
            return createAssessment(newAssessmentType).then((result) => {
              if (result.data?.newCompositionWithTemplate.ID) {
                navigate({
                  name: 'Assessment',
                  key: 'Assessment' + result.data.newCompositionWithTemplate.ID,
                  params: {
                    compositionID: result.data.newCompositionWithTemplate.ID,
                    patientID,
                  },
                });
              }
            });
          }}
          testID="PatientSectionQuestionnaires_startButton"
        />
      </View>
      <View style={{ backgroundColor: 'white', padding: 32, borderRadius: 16 }}>
        <OldHeading text="PHQ-9 Results" />
        <PHQRatings data={phq9s} />
      </View>
      <StaffTable
        renderEmpty={() => (
          <View style={{ alignItems: 'center', flex: 1, padding: 20 }}>
            <Text text="No questionnaires started yet" color={Color.styleGuide.Gray5} />
          </View>
        )}
        navigationExtractor={(item, index) => ({
          to: 'assessment',
          action: StackActions.push('Assessment', {
            patientID: patientID,
            compositionID: item.compositionID,
          }),
          params: {
            patientID: patientID,
            compositionID: item.compositionID,
          },
          testID: `PatientSectionQuestionnaires_row_${index}`,
        })}
        keyExtractor={(item) => item.compositionID}
        loading={loading}
        columns={[{ label: 'Questionnaire', grow: 2 }, 'Date', 'Results']}
        data={sortedData}
        renderItem={({ item, index }) => {
          const resultsStr = assessmentResultsString(item.assessment);
          return (
            <>
              <TableCell grow={2}>
                <Text
                  text={ASSESMENT_ABBREVIATION_BY_TYPE[item.assessment.type]}
                  weight="semibold"
                  style={{ flexShrink: 0 }}
                />
                <Text text={' ' + ASSESMENT_NAMES_BY_TYPE[item.assessment.type]} size={14} />
              </TableCell>
              <TableCell>
                <Text
                  text={
                    item.assessment.occurrenceDate
                      ? dateTimeFormatter.format(parseGQLDateTime(item.assessment.occurrenceDate))
                      : 'Not completed'
                  }
                />
              </TableCell>
              <TableCell>{resultsStr ? <Text text={resultsStr} /> : null}</TableCell>
            </>
          );
        }}
      />
    </View>
  );
}
