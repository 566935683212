import { useFocusEffect, useNavigation, useRoute } from '@react-navigation/native';
import { ReactNode, Ref, useCallback, useRef, useState } from 'react';
import { ImageURISource, StyleSheet, TouchableOpacity } from 'react-native';
import { KeyboardAwareScrollView as ScrollView } from 'react-native-keyboard-aware-scroll-view';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import MediaPlayer, { FullscreenMediaPlayer } from '@oui/app-core/src/components/MediaPlayer';
import { MobileHeaderStep } from '@oui/app-core/src/components/MobileHeaderStep';
import { QuizSetPreview } from '@oui/app-core/src/screens/QuizSet';
import { MyStoryMyPlanState } from '@oui/lib/src/types/avro/appState';

import { Text } from '@src/components/Text';
import { View } from '@src/components/View';
import { APP_SLUG } from '@src/constants';
import { useMyStoryMyPlanCompositionSectionSubscriptionData } from '@src/hooks/useComposition';
import { useWindowDimensions } from '@src/hooks/useWindowDimensions';
import { useI18n } from '@src/lib/i18n';
import { useTheme } from '@src/styles';
import { MyStoryMyPlanScreenProps, MyStoryMyPlanStackParamList } from '@src/types';

type Props = {
  heading: string;
  children: ReactNode;
  scrollViewRef?: Ref<ScrollView>;
  testID?: string;
};

const MOBILE_BORDER_RADIUS = 30;
const IS_EMBEDDED =
  global.window.parent?.location !== global.window.location || APP_SLUG !== 'oui-aviva';

export { MobileHeaderStep };

const ORDERED_STEPS = [
  'OVERVIEW',
  'MYSTORY__INTRODUCTION',
  'MYSTORY__TIMELINE',
  'MYSTORY__TIMELINE_REVIEW',
  'MYSTORY__TIMELINE_FINAL',
  'RISK_CURVE__INTRODUCTION',
  'RISK_CURVE__REVIEW',
  'MY_PLAN__INTRODUCTION',
  'MY_PLAN__REVIEW',
];

function MobileHeader({
  isControlled,
  currentStep,
}: {
  isControlled: boolean;
  currentStep: MyStoryMyPlanState['currentStep'];
}) {
  const { Color } = useTheme();
  const currentStepIndex = ORDERED_STEPS.indexOf(currentStep);
  const { navigate } =
    useNavigation<MyStoryMyPlanScreenProps<keyof MyStoryMyPlanStackParamList>['navigation']>();
  const { $t } = useI18n();

  return (
    <View
      row
      style={{
        paddingTop: 24 + 10,
        paddingBottom: 24 + MOBILE_BORDER_RADIUS,
        backgroundColor: Color.styleGuide.LogoLilac,
      }}
      pointerEvents={IS_EMBEDDED ? 'none' : undefined}
    >
      <MobileHeaderStep
        num={1}
        label={$t({ id: 'PatientMyStoryMyPlanContainer_myStory', defaultMessage: 'MyStory' })}
        complete={currentStepIndex >= 5}
        active={currentStepIndex >= 1 && currentStepIndex < 5}
        onPress={isControlled ? undefined : () => navigate('MyStoryIntroduction')}
      />
      <MobileHeaderStep
        num={2}
        label={$t({ id: 'PatientMyStoryMyPlanContainer_riskCurve', defaultMessage: 'Risk Curve' })}
        complete={currentStepIndex >= 7}
        active={currentStepIndex >= 5 && currentStepIndex < 7}
        onPress={isControlled ? undefined : () => navigate('MyStoryRiskCurveIntroduction')}
      />
      <MobileHeaderStep
        num={3}
        label={$t({ id: 'PatientMyStoryMyPlanContainer_myPlan', defaultMessage: 'MyPlan' })}
        complete={currentStepIndex >= 9}
        active={currentStepIndex >= 7}
        onPress={isControlled ? undefined : () => navigate('MyStoryMyPlanIntroduction')}
      />
    </View>
  );
}

export function PatientMyStoryMyPlanQuestionAnswer({
  question,
  answer,
}: {
  question: string;
  answer: ReactNode;
}) {
  return (
    <View spacing={12}>
      <Text text={question} size={17} weight="semibold" />
      {typeof answer === 'string' ? <Text text={answer} /> : answer}
    </View>
  );
}

export function PatientMyStoryMyPlanContainerSection({
  alternate,
  children,
  grow,
}: {
  alternate?: boolean;
  children: ReactNode;
  grow?: boolean;
}) {
  const { Color } = useTheme();
  return (
    <View
      style={{
        paddingHorizontal: 38,
        paddingBottom: 32,
        paddingTop: 16,
        backgroundColor: alternate ? Color.grayBackground : undefined,
        flexGrow: grow ? 1 : undefined,
      }}
    >
      {children}
    </View>
  );
}

// TODO consolidate with MyStoryMyPlanStack.tsx constant
const STEP_BY_SCREEN: Record<string, MyStoryMyPlanState['currentStep']> = {
  MyStoryMyPlanOverview: 'OVERVIEW',
  MyStoryIntroduction: 'MYSTORY__INTRODUCTION',
  MyStoryTimelineFinal: 'MYSTORY__TIMELINE_FINAL',
  MyStoryRiskCurveIntroduction: 'RISK_CURVE__INTRODUCTION',
  MyStoryRiskCurveReview: 'RISK_CURVE__REVIEW',
  MyStoryMyPlanIntroduction: 'MY_PLAN__INTRODUCTION',
  MyStoryMyPlanReview: 'MY_PLAN__REVIEW',
  MyStoryMyPlanComplete: 'COMPLETE',
};

export function PatientMyStoryMyPlanContainer(props: Props) {
  const { width } = useWindowDimensions();
  const isMobile = width < 768;
  const { Color } = useTheme();
  const { data } = useMyStoryMyPlanCompositionSectionSubscriptionData();
  const route = useRoute();
  const isControlled = route.name === 'ControlledMyStoryMyPlan';
  const currentStep = isControlled
    ? data?.APP_STATE.currentStep ?? 'OVERVIEW'
    : STEP_BY_SCREEN[route.name];
  const showMobileHeader = currentStep !== 'OVERVIEW' && currentStep !== 'COMPLETE';
  const insets = useSafeAreaInsets();

  return (
    <View
      style={{ flex: 1, paddingTop: insets.top, paddingBottom: insets.bottom }}
      testID={props.testID}
    >
      {showMobileHeader ? (
        <View style={StyleSheet.absoluteFillObject}>
          <View style={{ flex: 1, backgroundColor: Color.styleGuide.LogoLilac }} />
          <View style={{ flex: 1, backgroundColor: 'transparent' }} />
        </View>
      ) : null}
      <ScrollView
        style={{ flex: 1 }}
        contentContainerStyle={{
          flexGrow: 1,
          width: isMobile ? '100%' : 650,
          alignSelf: 'center',
        }}
        ref={props.scrollViewRef}
      >
        {showMobileHeader ? (
          <MobileHeader currentStep={currentStep} isControlled={isControlled} />
        ) : null}
        <View
          spacing={12}
          style={[
            {
              paddingTop: 26,
              flex: 1,
              backgroundColor: 'white',
            },
            showMobileHeader
              ? {
                  borderTopStartRadius: MOBILE_BORDER_RADIUS,
                  borderTopEndRadius: MOBILE_BORDER_RADIUS,
                  marginTop: -MOBILE_BORDER_RADIUS,
                }
              : null,
          ]}
          pointerEvents={IS_EMBEDDED ? 'none' : undefined}
          // @ts-expect-error
          dataSet={
            IS_EMBEDDED
              ? {
                  'no-pointer-events': '',
                }
              : null
          }
        >
          {props.heading ? (
            <Text
              text={props.heading}
              size={isMobile ? 21 : 40}
              style={{
                paddingHorizontal: 38,
                lineHeight: isMobile ? 22 : 55,
              }}
              color={Color.tertiary}
              weight="bold"
            />
          ) : null}
          {props.children}
        </View>
      </ScrollView>
    </View>
  );
}

export function ExplanationVideos(props: {
  videos: Array<
    | {
        uri: string;
        caption: string;
        posterSource?: ImageURISource | number;
      }
    | {
        slug: string;
        caption: string;
        linkText?: string;
      }
  >;
}) {
  const { Color } = useTheme();
  const { $t } = useI18n();
  const { width } = useWindowDimensions();
  const isMobile = width < 768;
  const [layoutWidth, setLayoutWidth] = useState(0);
  const { navigate } =
    useNavigation<MyStoryMyPlanScreenProps<keyof MyStoryMyPlanStackParamList>['navigation']>();

  const player = useRef<MediaPlayer>(null);
  const player2 = useRef<MediaPlayer>(null);

  useFocusEffect(
    useCallback(() => {
      return () => {
        if (player.current) {
          player.current.setIsPlayingAsync(false);
        }
        if (player2.current) {
          player2.current.setIsPlayingAsync(false);
        }
      };
    }, []),
  );

  return (
    <PatientMyStoryMyPlanContainerSection alternate>
      <View
        spacing={26}
        onLayout={(e) => {
          if (layoutWidth !== e.nativeEvent.layout.width) {
            setLayoutWidth(e.nativeEvent.layout.width);
          }
        }}
      >
        <Text
          text={$t({ id: 'PatientMyStoryMyPlanContainer_videoHeading', defaultMessage: 'Videos' })}
          size={17}
          weight="semibold"
        />
        {props.videos.map((video) => {
          if ('slug' in video) {
            const previewPadding = video.slug.includes('VOICEOVER') ? 0 : 20;
            const borderWidth = 2;
            const previewWidth = layoutWidth - previewPadding * 2 - borderWidth * 2;

            return (
              <View key={video.slug} spacing={12}>
                <Text size={isMobile ? 17 : 21} text={video.caption} />
                {layoutWidth ? (
                  <TouchableOpacity onPress={() => navigate('QuizSet', { slug: video.slug })}>
                    <View
                      style={{
                        borderColor: Color.accent,
                        padding: previewPadding,
                        backgroundColor: 'white',
                        borderWidth: 2,
                        borderRadius: 20,
                      }}
                    >
                      <QuizSetPreview
                        width={previewWidth}
                        height={(previewWidth * 9) / 16}
                        slug={video.slug}
                        key={video.slug}
                      />
                      {previewPadding ? (
                        <Text
                          text={$t({
                            id: 'PatientMyStoryMyPlanContainer_watchVideoButton',
                            defaultMessage: 'Watch',
                          })}
                          textAlign="center"
                          weight="semibold"
                          style={{ marginTop: 8 }}
                          color={Color.accent}
                        />
                      ) : null}
                    </View>
                  </TouchableOpacity>
                ) : null}
              </View>
            );
          }

          return (
            <View spacing={12} key={video.uri || video.caption}>
              <Text size={isMobile ? 17 : 21} text={video.caption} />
              <View flex={1} />
              <FullscreenMediaPlayer
                uri={video.uri || undefined!}
                ref={player}
                posterSource={video.posterSource}
                mediaAspectRatio={9 / 16}
                posterInitialPositionMillis={5000}
              />
            </View>
          );
        })}
      </View>
    </PatientMyStoryMyPlanContainerSection>
  );
}
