// This file is automatically generated. See gqlcodegen.yml for details
// prettier-ignore
// @ts-ignore
import * as CustomScalars from "@oui/lib/src/types/scalars";
import * as Types from '@oui/lib/src/types/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export type IssueActivationTokenMutationVariables = Types.Exact<{
  patientID: Types.Scalars['UUID']['input'];
}>;


export type IssueActivationTokenMutation = { issueToken: { value: string, expiresAt: CustomScalars.GQLDateTime } };

export type AddPatientMutationVariables = Types.Exact<{
  organizationID: Types.Scalars['UUID']['input'];
  patient: Types.PatientInput;
  primaryPractitionerID: Types.Scalars['UUID']['input'];
}>;


export type AddPatientMutation = { addPatient: { ID: CustomScalars.GQLUUID } };


export const IssueActivationTokenDocument = gql`
    mutation IssueActivationToken($patientID: UUID!) {
  issueToken(patientID: $patientID) {
    value
    expiresAt
  }
}
    `;

/**
 * __useIssueActivationTokenMutation__
 *
 * To run a mutation, you first call `useIssueActivationTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIssueActivationTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [issueActivationTokenMutation, { data, loading, error }] = useIssueActivationTokenMutation({
 *   variables: {
 *      patientID: // value for 'patientID'
 *   },
 * });
 */
export function useIssueActivationTokenMutation(baseOptions?: Apollo.MutationHookOptions<IssueActivationTokenMutation, IssueActivationTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IssueActivationTokenMutation, IssueActivationTokenMutationVariables>(IssueActivationTokenDocument, options);
      }
export type IssueActivationTokenMutationHookResult = ReturnType<typeof useIssueActivationTokenMutation>;

export function performIssueActivationToken(client: Apollo.ApolloClient<unknown>, variables: IssueActivationTokenMutationVariables, options?: Omit<Apollo.MutationOptions<IssueActivationTokenMutation, IssueActivationTokenMutationVariables>, "mutation" | 'variables'>) {
  return client.mutate<IssueActivationTokenMutation, IssueActivationTokenMutationVariables>({
    mutation: IssueActivationTokenDocument,
    variables,
    ...options,
  });
}

export const AddPatientDocument = gql`
    mutation AddPatient($organizationID: UUID!, $patient: PatientInput!, $primaryPractitionerID: UUID!) {
  addPatient(
    organizationID: $organizationID
    patient: $patient
    primaryPractitionerID: $primaryPractitionerID
  ) {
    ID
  }
}
    `;

/**
 * __useAddPatientMutation__
 *
 * To run a mutation, you first call `useAddPatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPatientMutation, { data, loading, error }] = useAddPatientMutation({
 *   variables: {
 *      organizationID: // value for 'organizationID'
 *      patient: // value for 'patient'
 *      primaryPractitionerID: // value for 'primaryPractitionerID'
 *   },
 * });
 */
export function useAddPatientMutation(baseOptions?: Apollo.MutationHookOptions<AddPatientMutation, AddPatientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPatientMutation, AddPatientMutationVariables>(AddPatientDocument, options);
      }
export type AddPatientMutationHookResult = ReturnType<typeof useAddPatientMutation>;

export function performAddPatient(client: Apollo.ApolloClient<unknown>, variables: AddPatientMutationVariables, options?: Omit<Apollo.MutationOptions<AddPatientMutation, AddPatientMutationVariables>, "mutation" | 'variables'>) {
  return client.mutate<AddPatientMutation, AddPatientMutationVariables>({
    mutation: AddPatientDocument,
    variables,
    ...options,
  });
}
