import { QueryHookOptions, useQuery } from '@apollo/client';

import { graphql, ResultOf, VariablesOf } from '@oui/lib/src/graphql/tada';
import { GQLUUID } from '@oui/lib/src/types/scalars';

export const PatientQuery = graphql(`
  query Patient($patientID: UUID!) {
    patientByPatientID(patientID: $patientID) {
      __typename
      ID
      patient {
        __typename
        ID
        productVersion
        productVariant
        productStatic
        onboardingVariant
        caringContactsMedium
        createdAt
        person {
          email
          givenName
          familyName
          race
          birthDate
          genderIdentity
          sexualOrientation
          militaryStatus
          address {
            use
            line
            city
            state
            postalCode
          }
          contactPoint {
            ID
            system
            use
            value
          }
        }
      }
      supporters {
        supporter {
          ID
          person {
            givenName
            familyName
            userID
          }
          createdAt
        }
        relation
        claimed
        supporterID
        patientID
      }
      progress {
        completed
        completion
        content
        updatedAt
      }
      lastLogin

      careTeam {
        organizations {
          ID
          isTrialOrganization
        }
      }
    }
  }
`);

type Options = QueryHookOptions<ResultOf<typeof PatientQuery>, VariablesOf<typeof PatientQuery>>;
export function usePatient(patientID: GQLUUID, options?: Omit<Options, 'variables'>) {
  return useQuery(PatientQuery, {
    variables: {
      patientID,
    },
    skip: !patientID,
    ...options,
  });
}
