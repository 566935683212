// This file is automatically generated. See gqlcodegen.yml for details
// prettier-ignore
// @ts-ignore
import * as CustomScalars from "@oui/lib/src/types/scalars";
import * as Types from '@oui/lib/src/types/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TrialAssignmentsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type TrialAssignmentsQuery = { productVariantAssignmentsForTrial: Array<{ givenName: string, familyName: string, email: string, trialArmAssignment: string, organizationName: string, createdAt: CustomScalars.GQLDateTime }> };


export const TrialAssignmentsDocument = gql`
    query TrialAssignments {
  productVariantAssignmentsForTrial {
    givenName
    familyName
    email
    trialArmAssignment
    organizationName
    createdAt
  }
}
    `;

/**
 * __useTrialAssignmentsQuery__
 *
 * To run a query within a React component, call `useTrialAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrialAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrialAssignmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTrialAssignmentsQuery(baseOptions?: Apollo.QueryHookOptions<TrialAssignmentsQuery, TrialAssignmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TrialAssignmentsQuery, TrialAssignmentsQueryVariables>(TrialAssignmentsDocument, options);
      }
export function useTrialAssignmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TrialAssignmentsQuery, TrialAssignmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TrialAssignmentsQuery, TrialAssignmentsQueryVariables>(TrialAssignmentsDocument, options);
        }
export type TrialAssignmentsQueryHookResult = ReturnType<typeof useTrialAssignmentsQuery>;
export type TrialAssignmentsLazyQueryHookResult = ReturnType<typeof useTrialAssignmentsLazyQuery>;

export function queryTrialAssignments(client: Apollo.ApolloClient<unknown>, variables?: TrialAssignmentsQueryVariables | undefined, options?: Omit<Apollo.QueryOptions<TrialAssignmentsQuery, TrialAssignmentsQueryVariables>, "query" | 'variables'>) {
  return client.query<TrialAssignmentsQuery, TrialAssignmentsQueryVariables>({
    query: TrialAssignmentsDocument,
    variables,
    ...options,
  });
}
