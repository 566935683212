// This file is automatically generated. See gqlcodegen.yml for details
// prettier-ignore
// @ts-ignore
import * as CustomScalars from "@oui/lib/src/types/scalars";
import * as Types from '@oui/lib/src/types/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AdminOrganizationQueryVariables = Types.Exact<{
  organizationID: Types.Scalars['UUID']['input'];
  inheritMembers?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type AdminOrganizationQuery = { organizationByID: { __typename: 'Organization', ID: CustomScalars.GQLUUID, name: string, productVersion: Types.ProductVersion, productVariants: Array<Types.ProductVariant>, onboardingVariant: Types.OnboardingVariant, caringContactsEnabled: boolean, children: Array<{ __typename: 'Organization', ID: CustomScalars.GQLUUID, name: string }>, members: Array<{ __typename: 'OrganizationMember', userID: CustomScalars.GQLUUID, roles: Array<Types.OuiUserRoleType>, person: { email: string | null, givenName: string | null, familyName: string | null, lastLogin: CustomScalars.GQLDateTime | null }, userRoles: Array<{ ID: CustomScalars.GQLUUID, role: Types.OuiUserRoleType }> }>, parent: { __typename: 'Organization', ID: CustomScalars.GQLUUID, name: string, children: Array<{ __typename: 'Organization', ID: CustomScalars.GQLUUID, name: string }>, members: Array<{ __typename: 'OrganizationMember', userID: CustomScalars.GQLUUID }> } | null, practitioners: Array<{ __typename: 'Practitioner', ID: CustomScalars.GQLUUID, npi: number | null, person: { email: string | null, givenName: string | null, familyName: string | null } }> | null, stats: { __typename: 'OrganizationStats', registrarCount: number, practitionerCount: number } } | null };

export type AddOrganizationMemberMutationVariables = Types.Exact<{
  organizationID: Types.Scalars['UUID']['input'];
  person: Types.PersonWithEmailInput;
  roles: Array<Types.OuiUserRoleType>;
}>;


export type AddOrganizationMemberMutation = { addOrganizationMember: { __typename: 'OrganizationMemberResult', member: { __typename: 'OrganizationMember', userID: CustomScalars.GQLUUID, roles: Array<Types.OuiUserRoleType>, person: { userID: CustomScalars.GQLUUID, givenName: string | null, familyName: string | null }, userRoles: Array<{ ID: CustomScalars.GQLUUID, role: Types.OuiUserRoleType }> } } };

export type UpdateOrganizationMemberMutationVariables = Types.Exact<{
  organizationID: Types.Scalars['UUID']['input'];
  userID: Types.Scalars['UUID']['input'];
  update: Types.OrganizationMemberUpdateInput;
}>;


export type UpdateOrganizationMemberMutation = { updateOrganizationMember: { __typename: 'OrganizationMemberResult', member: { __typename: 'OrganizationMember', userID: CustomScalars.GQLUUID, roles: Array<Types.OuiUserRoleType>, person: { userID: CustomScalars.GQLUUID, givenName: string | null, familyName: string | null }, userRoles: Array<{ ID: CustomScalars.GQLUUID, role: Types.OuiUserRoleType }> } } };

export type ReplacePrimaryPractitionerMutationVariables = Types.Exact<{
  currentPrimaryPractitionerID: Types.Scalars['UUID']['input'];
  newPrimaryPractitionerID: Types.Scalars['UUID']['input'];
}>;


export type ReplacePrimaryPractitionerMutation = { replacePrimaryPractitioner: { success: boolean } };


export const AdminOrganizationDocument = gql`
    query AdminOrganization($organizationID: UUID!, $inheritMembers: Boolean) {
  organizationByID(organizationID: $organizationID) {
    __typename
    ID
    name
    children {
      __typename
      ID
      name
    }
    members(recurse: $inheritMembers) {
      __typename
      userID
      person {
        email
        givenName
        familyName
        lastLogin
      }
      roles
      userRoles {
        ID
        role
      }
    }
    productVersion
    productVariants
    onboardingVariant
    caringContactsEnabled
    parent {
      __typename
      ID
      name
      children {
        __typename
        ID
        name
      }
      members {
        __typename
        userID
      }
    }
    practitioners(recurse: $inheritMembers) {
      __typename
      ID
      npi
      person {
        email
        givenName
        familyName
      }
    }
    stats {
      __typename
      registrarCount
      practitionerCount
    }
  }
}
    `;

/**
 * __useAdminOrganizationQuery__
 *
 * To run a query within a React component, call `useAdminOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminOrganizationQuery({
 *   variables: {
 *      organizationID: // value for 'organizationID'
 *      inheritMembers: // value for 'inheritMembers'
 *   },
 * });
 */
export function useAdminOrganizationQuery(baseOptions: Apollo.QueryHookOptions<AdminOrganizationQuery, AdminOrganizationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminOrganizationQuery, AdminOrganizationQueryVariables>(AdminOrganizationDocument, options);
      }
export function useAdminOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminOrganizationQuery, AdminOrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminOrganizationQuery, AdminOrganizationQueryVariables>(AdminOrganizationDocument, options);
        }
export type AdminOrganizationQueryHookResult = ReturnType<typeof useAdminOrganizationQuery>;
export type AdminOrganizationLazyQueryHookResult = ReturnType<typeof useAdminOrganizationLazyQuery>;

export function queryAdminOrganization(client: Apollo.ApolloClient<unknown>, variables: AdminOrganizationQueryVariables, options?: Omit<Apollo.QueryOptions<AdminOrganizationQuery, AdminOrganizationQueryVariables>, "query" | 'variables'>) {
  return client.query<AdminOrganizationQuery, AdminOrganizationQueryVariables>({
    query: AdminOrganizationDocument,
    variables,
    ...options,
  });
}

export const AddOrganizationMemberDocument = gql`
    mutation AddOrganizationMember($organizationID: UUID!, $person: PersonWithEmailInput!, $roles: [OuiUserRoleType!]!) {
  addOrganizationMember(
    organizationID: $organizationID
    person: $person
    roles: $roles
  ) {
    __typename
    member {
      __typename
      userID
      person {
        userID
        givenName
        familyName
      }
      roles
      userRoles {
        ID
        role
      }
    }
  }
}
    `;

/**
 * __useAddOrganizationMemberMutation__
 *
 * To run a mutation, you first call `useAddOrganizationMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOrganizationMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOrganizationMemberMutation, { data, loading, error }] = useAddOrganizationMemberMutation({
 *   variables: {
 *      organizationID: // value for 'organizationID'
 *      person: // value for 'person'
 *      roles: // value for 'roles'
 *   },
 * });
 */
export function useAddOrganizationMemberMutation(baseOptions?: Apollo.MutationHookOptions<AddOrganizationMemberMutation, AddOrganizationMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddOrganizationMemberMutation, AddOrganizationMemberMutationVariables>(AddOrganizationMemberDocument, options);
      }
export type AddOrganizationMemberMutationHookResult = ReturnType<typeof useAddOrganizationMemberMutation>;

export function performAddOrganizationMember(client: Apollo.ApolloClient<unknown>, variables: AddOrganizationMemberMutationVariables, options?: Omit<Apollo.MutationOptions<AddOrganizationMemberMutation, AddOrganizationMemberMutationVariables>, "mutation" | 'variables'>) {
  return client.mutate<AddOrganizationMemberMutation, AddOrganizationMemberMutationVariables>({
    mutation: AddOrganizationMemberDocument,
    variables,
    ...options,
  });
}

export const UpdateOrganizationMemberDocument = gql`
    mutation UpdateOrganizationMember($organizationID: UUID!, $userID: UUID!, $update: OrganizationMemberUpdateInput!) {
  updateOrganizationMember(
    organizationID: $organizationID
    userID: $userID
    update: $update
  ) {
    __typename
    member {
      __typename
      userID
      person {
        userID
        givenName
        familyName
      }
      roles
      userRoles {
        ID
        role
      }
    }
  }
}
    `;

/**
 * __useUpdateOrganizationMemberMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationMemberMutation, { data, loading, error }] = useUpdateOrganizationMemberMutation({
 *   variables: {
 *      organizationID: // value for 'organizationID'
 *      userID: // value for 'userID'
 *      update: // value for 'update'
 *   },
 * });
 */
export function useUpdateOrganizationMemberMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrganizationMemberMutation, UpdateOrganizationMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrganizationMemberMutation, UpdateOrganizationMemberMutationVariables>(UpdateOrganizationMemberDocument, options);
      }
export type UpdateOrganizationMemberMutationHookResult = ReturnType<typeof useUpdateOrganizationMemberMutation>;

export function performUpdateOrganizationMember(client: Apollo.ApolloClient<unknown>, variables: UpdateOrganizationMemberMutationVariables, options?: Omit<Apollo.MutationOptions<UpdateOrganizationMemberMutation, UpdateOrganizationMemberMutationVariables>, "mutation" | 'variables'>) {
  return client.mutate<UpdateOrganizationMemberMutation, UpdateOrganizationMemberMutationVariables>({
    mutation: UpdateOrganizationMemberDocument,
    variables,
    ...options,
  });
}

export const ReplacePrimaryPractitionerDocument = gql`
    mutation ReplacePrimaryPractitioner($currentPrimaryPractitionerID: UUID!, $newPrimaryPractitionerID: UUID!) {
  replacePrimaryPractitioner(
    currentPrimaryPractitionerID: $currentPrimaryPractitionerID
    newPrimaryPractitionerID: $newPrimaryPractitionerID
  ) {
    success
  }
}
    `;

/**
 * __useReplacePrimaryPractitionerMutation__
 *
 * To run a mutation, you first call `useReplacePrimaryPractitionerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReplacePrimaryPractitionerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [replacePrimaryPractitionerMutation, { data, loading, error }] = useReplacePrimaryPractitionerMutation({
 *   variables: {
 *      currentPrimaryPractitionerID: // value for 'currentPrimaryPractitionerID'
 *      newPrimaryPractitionerID: // value for 'newPrimaryPractitionerID'
 *   },
 * });
 */
export function useReplacePrimaryPractitionerMutation(baseOptions?: Apollo.MutationHookOptions<ReplacePrimaryPractitionerMutation, ReplacePrimaryPractitionerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReplacePrimaryPractitionerMutation, ReplacePrimaryPractitionerMutationVariables>(ReplacePrimaryPractitionerDocument, options);
      }
export type ReplacePrimaryPractitionerMutationHookResult = ReturnType<typeof useReplacePrimaryPractitionerMutation>;

export function performReplacePrimaryPractitioner(client: Apollo.ApolloClient<unknown>, variables: ReplacePrimaryPractitionerMutationVariables, options?: Omit<Apollo.MutationOptions<ReplacePrimaryPractitionerMutation, ReplacePrimaryPractitionerMutationVariables>, "mutation" | 'variables'>) {
  return client.mutate<ReplacePrimaryPractitionerMutation, ReplacePrimaryPractitionerMutationVariables>({
    mutation: ReplacePrimaryPractitionerDocument,
    variables,
    ...options,
  });
}
