// This file is automatically generated. See gqlcodegen.yml for details
// prettier-ignore
// @ts-ignore
import * as CustomScalars from "@oui/lib/src/types/scalars";
import * as Types from '@oui/lib/src/types/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CareTeamFragmentFragment = { __typename: 'CareTeam', ID: CustomScalars.GQLUUID, organizations: Array<{ __typename: 'Organization', ID: CustomScalars.GQLUUID, practitioners: Array<{ __typename: 'Practitioner', ID: CustomScalars.GQLUUID, person: { givenName: string | null, familyName: string | null } }> | null }>, primaryPractitioner: { __typename: 'Practitioner', ID: CustomScalars.GQLUUID, person: { givenName: string | null, familyName: string | null } } | null, practitioners: Array<{ __typename: 'Practitioner', ID: CustomScalars.GQLUUID, person: { givenName: string | null, familyName: string | null } }> };

export type PatientCareTeamQueryVariables = Types.Exact<{
  patientID: Types.Scalars['UUID']['input'];
}>;


export type PatientCareTeamQuery = { careTeamByPatientID: { __typename: 'CareTeam', ID: CustomScalars.GQLUUID, organizations: Array<{ __typename: 'Organization', ID: CustomScalars.GQLUUID, practitioners: Array<{ __typename: 'Practitioner', ID: CustomScalars.GQLUUID, person: { givenName: string | null, familyName: string | null } }> | null }>, primaryPractitioner: { __typename: 'Practitioner', ID: CustomScalars.GQLUUID, person: { givenName: string | null, familyName: string | null } } | null, practitioners: Array<{ __typename: 'Practitioner', ID: CustomScalars.GQLUUID, person: { givenName: string | null, familyName: string | null } }> } };

export type AddPractitionerToCareTeamMutationVariables = Types.Exact<{
  careTeamID: Types.Scalars['UUID']['input'];
  practitionerID: Types.Scalars['UUID']['input'];
}>;


export type AddPractitionerToCareTeamMutation = { addPractitionerToCareTeam: { __typename: 'CareTeam', ID: CustomScalars.GQLUUID, organizations: Array<{ __typename: 'Organization', ID: CustomScalars.GQLUUID, practitioners: Array<{ __typename: 'Practitioner', ID: CustomScalars.GQLUUID, person: { givenName: string | null, familyName: string | null } }> | null }>, primaryPractitioner: { __typename: 'Practitioner', ID: CustomScalars.GQLUUID, person: { givenName: string | null, familyName: string | null } } | null, practitioners: Array<{ __typename: 'Practitioner', ID: CustomScalars.GQLUUID, person: { givenName: string | null, familyName: string | null } }> } };

export type RemovePractitionerFromCareTeamMutationVariables = Types.Exact<{
  careTeamID: Types.Scalars['UUID']['input'];
  practitionerID: Types.Scalars['UUID']['input'];
}>;


export type RemovePractitionerFromCareTeamMutation = { removePractitionerFromCareTeam: { __typename: 'CareTeam', ID: CustomScalars.GQLUUID, organizations: Array<{ __typename: 'Organization', ID: CustomScalars.GQLUUID, practitioners: Array<{ __typename: 'Practitioner', ID: CustomScalars.GQLUUID, person: { givenName: string | null, familyName: string | null } }> | null }>, primaryPractitioner: { __typename: 'Practitioner', ID: CustomScalars.GQLUUID, person: { givenName: string | null, familyName: string | null } } | null, practitioners: Array<{ __typename: 'Practitioner', ID: CustomScalars.GQLUUID, person: { givenName: string | null, familyName: string | null } }> } };

export type SetCareTeamPrimaryPractitionerMutationVariables = Types.Exact<{
  careTeamID: Types.Scalars['UUID']['input'];
  practitionerID: Types.Scalars['UUID']['input'];
}>;


export type SetCareTeamPrimaryPractitionerMutation = { setCareTeamPrimaryPractitioner: { __typename: 'CareTeam', ID: CustomScalars.GQLUUID, organizations: Array<{ __typename: 'Organization', ID: CustomScalars.GQLUUID, practitioners: Array<{ __typename: 'Practitioner', ID: CustomScalars.GQLUUID, person: { givenName: string | null, familyName: string | null } }> | null }>, primaryPractitioner: { __typename: 'Practitioner', ID: CustomScalars.GQLUUID, person: { givenName: string | null, familyName: string | null } } | null, practitioners: Array<{ __typename: 'Practitioner', ID: CustomScalars.GQLUUID, person: { givenName: string | null, familyName: string | null } }> } };

export const CareTeamFragmentFragmentDoc = gql`
    fragment CareTeamFragment on CareTeam {
  __typename
  ID
  organizations {
    __typename
    ID
    practitioners {
      __typename
      ID
      person {
        givenName
        familyName
      }
    }
  }
  primaryPractitioner {
    __typename
    ID
    person {
      givenName
      familyName
    }
  }
  practitioners {
    __typename
    ID
    person {
      givenName
      familyName
    }
  }
}
    `;
export const PatientCareTeamDocument = gql`
    query PatientCareTeam($patientID: UUID!) {
  careTeamByPatientID(patientID: $patientID) {
    ...CareTeamFragment
  }
}
    ${CareTeamFragmentFragmentDoc}`;

/**
 * __usePatientCareTeamQuery__
 *
 * To run a query within a React component, call `usePatientCareTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientCareTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientCareTeamQuery({
 *   variables: {
 *      patientID: // value for 'patientID'
 *   },
 * });
 */
export function usePatientCareTeamQuery(baseOptions: Apollo.QueryHookOptions<PatientCareTeamQuery, PatientCareTeamQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PatientCareTeamQuery, PatientCareTeamQueryVariables>(PatientCareTeamDocument, options);
      }
export function usePatientCareTeamLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PatientCareTeamQuery, PatientCareTeamQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PatientCareTeamQuery, PatientCareTeamQueryVariables>(PatientCareTeamDocument, options);
        }
export type PatientCareTeamQueryHookResult = ReturnType<typeof usePatientCareTeamQuery>;
export type PatientCareTeamLazyQueryHookResult = ReturnType<typeof usePatientCareTeamLazyQuery>;

export function queryPatientCareTeam(client: Apollo.ApolloClient<unknown>, variables: PatientCareTeamQueryVariables, options?: Omit<Apollo.QueryOptions<PatientCareTeamQuery, PatientCareTeamQueryVariables>, "query" | 'variables'>) {
  return client.query<PatientCareTeamQuery, PatientCareTeamQueryVariables>({
    query: PatientCareTeamDocument,
    variables,
    ...options,
  });
}

export const AddPractitionerToCareTeamDocument = gql`
    mutation AddPractitionerToCareTeam($careTeamID: UUID!, $practitionerID: UUID!) {
  addPractitionerToCareTeam(
    careTeamID: $careTeamID
    practitionerID: $practitionerID
  ) {
    ...CareTeamFragment
  }
}
    ${CareTeamFragmentFragmentDoc}`;

/**
 * __useAddPractitionerToCareTeamMutation__
 *
 * To run a mutation, you first call `useAddPractitionerToCareTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPractitionerToCareTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPractitionerToCareTeamMutation, { data, loading, error }] = useAddPractitionerToCareTeamMutation({
 *   variables: {
 *      careTeamID: // value for 'careTeamID'
 *      practitionerID: // value for 'practitionerID'
 *   },
 * });
 */
export function useAddPractitionerToCareTeamMutation(baseOptions?: Apollo.MutationHookOptions<AddPractitionerToCareTeamMutation, AddPractitionerToCareTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPractitionerToCareTeamMutation, AddPractitionerToCareTeamMutationVariables>(AddPractitionerToCareTeamDocument, options);
      }
export type AddPractitionerToCareTeamMutationHookResult = ReturnType<typeof useAddPractitionerToCareTeamMutation>;

export function performAddPractitionerToCareTeam(client: Apollo.ApolloClient<unknown>, variables: AddPractitionerToCareTeamMutationVariables, options?: Omit<Apollo.MutationOptions<AddPractitionerToCareTeamMutation, AddPractitionerToCareTeamMutationVariables>, "mutation" | 'variables'>) {
  return client.mutate<AddPractitionerToCareTeamMutation, AddPractitionerToCareTeamMutationVariables>({
    mutation: AddPractitionerToCareTeamDocument,
    variables,
    ...options,
  });
}

export const RemovePractitionerFromCareTeamDocument = gql`
    mutation RemovePractitionerFromCareTeam($careTeamID: UUID!, $practitionerID: UUID!) {
  removePractitionerFromCareTeam(
    careTeamID: $careTeamID
    practitionerID: $practitionerID
  ) {
    ...CareTeamFragment
  }
}
    ${CareTeamFragmentFragmentDoc}`;

/**
 * __useRemovePractitionerFromCareTeamMutation__
 *
 * To run a mutation, you first call `useRemovePractitionerFromCareTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePractitionerFromCareTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePractitionerFromCareTeamMutation, { data, loading, error }] = useRemovePractitionerFromCareTeamMutation({
 *   variables: {
 *      careTeamID: // value for 'careTeamID'
 *      practitionerID: // value for 'practitionerID'
 *   },
 * });
 */
export function useRemovePractitionerFromCareTeamMutation(baseOptions?: Apollo.MutationHookOptions<RemovePractitionerFromCareTeamMutation, RemovePractitionerFromCareTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemovePractitionerFromCareTeamMutation, RemovePractitionerFromCareTeamMutationVariables>(RemovePractitionerFromCareTeamDocument, options);
      }
export type RemovePractitionerFromCareTeamMutationHookResult = ReturnType<typeof useRemovePractitionerFromCareTeamMutation>;

export function performRemovePractitionerFromCareTeam(client: Apollo.ApolloClient<unknown>, variables: RemovePractitionerFromCareTeamMutationVariables, options?: Omit<Apollo.MutationOptions<RemovePractitionerFromCareTeamMutation, RemovePractitionerFromCareTeamMutationVariables>, "mutation" | 'variables'>) {
  return client.mutate<RemovePractitionerFromCareTeamMutation, RemovePractitionerFromCareTeamMutationVariables>({
    mutation: RemovePractitionerFromCareTeamDocument,
    variables,
    ...options,
  });
}

export const SetCareTeamPrimaryPractitionerDocument = gql`
    mutation SetCareTeamPrimaryPractitioner($careTeamID: UUID!, $practitionerID: UUID!) {
  setCareTeamPrimaryPractitioner(
    careTeamID: $careTeamID
    practitionerID: $practitionerID
  ) {
    ...CareTeamFragment
  }
}
    ${CareTeamFragmentFragmentDoc}`;

/**
 * __useSetCareTeamPrimaryPractitionerMutation__
 *
 * To run a mutation, you first call `useSetCareTeamPrimaryPractitionerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCareTeamPrimaryPractitionerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCareTeamPrimaryPractitionerMutation, { data, loading, error }] = useSetCareTeamPrimaryPractitionerMutation({
 *   variables: {
 *      careTeamID: // value for 'careTeamID'
 *      practitionerID: // value for 'practitionerID'
 *   },
 * });
 */
export function useSetCareTeamPrimaryPractitionerMutation(baseOptions?: Apollo.MutationHookOptions<SetCareTeamPrimaryPractitionerMutation, SetCareTeamPrimaryPractitionerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetCareTeamPrimaryPractitionerMutation, SetCareTeamPrimaryPractitionerMutationVariables>(SetCareTeamPrimaryPractitionerDocument, options);
      }
export type SetCareTeamPrimaryPractitionerMutationHookResult = ReturnType<typeof useSetCareTeamPrimaryPractitionerMutation>;

export function performSetCareTeamPrimaryPractitioner(client: Apollo.ApolloClient<unknown>, variables: SetCareTeamPrimaryPractitionerMutationVariables, options?: Omit<Apollo.MutationOptions<SetCareTeamPrimaryPractitionerMutation, SetCareTeamPrimaryPractitionerMutationVariables>, "mutation" | 'variables'>) {
  return client.mutate<SetCareTeamPrimaryPractitionerMutation, SetCareTeamPrimaryPractitionerMutationVariables>({
    mutation: SetCareTeamPrimaryPractitionerDocument,
    variables,
    ...options,
  });
}
