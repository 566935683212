import { produce } from 'immer';
import isEqual from 'lodash/isEqual';
import { memo, ReactNode, useCallback } from 'react';

import { Button } from '@src/components/Button';
import { Checkbox } from '@src/components/Checkbox';
import { Text } from '@src/components/Text';
import { TextInput } from '@src/components/TextInput';
import { View } from '@src/components/View';
import { useForm } from '@src/hooks/useForm';
import { IntlShape, useI18n } from '@src/lib/i18n';

export type Question = {
  index?: number;
  label?: string;
  sublabel?: string;
  followups?: { [key: string]: Question[] };
} & (
  | {
      type: 'yesno';
    }
  | {
      type: 'text';
      placeholder?: string;
      other?: boolean;
    }
  | {
      type: 'multichoice' | 'singlechoice';
      ui?: { horizontal?: boolean };
      choices: { label: string; value: string | number }[];
    }
);

export type Answer = {
  value?: (string | boolean | number)[];
  followups?: FormAnswers;
};

export type FormAnswers = { [key: string]: Answer[] };

export type Schema = { [key: string]: Question };

const Followups = memo(function Followups({
  testIDPrefix,
  depth,
  questions,
  answers,
  onChangeAnswers,
}: {
  testIDPrefix: string;
  depth: number;
  questions: Question[];
  answers: Answer[];
  onChangeAnswers: (newAnswers: Answer[]) => void;
}) {
  return (
    <>
      {questions?.map((followup: Question, j: number) => {
        return (
          <Question
            testID={`${testIDPrefix}__followup__${j}`}
            depth={depth}
            key={j}
            question={followup}
            answer={answers?.[j]}
            onChangeValue={(newAns) => {
              const followups = produce(answers, (draft) => {
                draft[j] = newAns;
              });
              onChangeAnswers(followups);
            }}
          />
        );
      }) ?? null}
    </>
  );
});

const Choice = memo(function Choice({
  testID,
  depth,
  question,
  answer,
  label,
  value,
  onChangeAnswer,
}: {
  testID: string;
  depth: number;
  question: Question;
  answer?: Answer;
  label: string;
  value: string | number;
  onChangeAnswer: (ans: Answer) => void;
}) {
  const values = answer?.value;
  const checked = values && values.findIndex((v) => v === value) !== -1;
  const questionType = question.type;
  const onChangeValue = useCallback(() => {
    const answerValue =
      questionType === 'singlechoice'
        ? [value]
        : values?.includes(value)
          ? values.filter((v) => v !== value)
          : [...(values ?? []), value];
    onChangeAnswer({ value: answerValue });
  }, [onChangeAnswer, values, value, questionType]);

  return (
    <View spacing={8}>
      <Checkbox
        testID={testID}
        label={label}
        variant={question.type === 'singlechoice' ? 'radio' : 'checkbox'}
        horizontal
        value={checked}
        onChangeValue={onChangeValue}
      />
      {checked && question.followups?.[value] ? (
        <Followups
          testIDPrefix={testID}
          depth={depth + 1}
          questions={question.followups?.[value] || []}
          answers={answer?.followups?.[value] || []}
          onChangeAnswers={(newAnswers) => {
            const followups = produce(answer?.followups ?? {}, (draft) => {
              if (!draft[value]) draft[value] = [];
              draft[value] = newAnswers;
            });
            onChangeAnswer({ followups });
          }}
        />
      ) : null}
    </View>
  );
}, isEqual);

type QuestionProps = {
  testID: string;
  onChangeValue: (answer: Answer) => void;
  sequenceNumber?: string;
  answer?: Answer;
  question: Question;
  depth: number;
};
const Question = memo(function Question({
  testID,
  depth,
  question,
  sequenceNumber,
  answer,
  onChangeValue,
}: QuestionProps) {
  const { $t } = useI18n();
  const onChangeAnswer = useCallback(
    (partial: Partial<Answer>) => {
      onChangeValue({ ...answer, ...partial });
    },
    [answer, onChangeValue],
  );

  let outer = null;

  switch (question.type) {
    case 'multichoice':
    case 'singlechoice':
      const horizontal = question.ui?.horizontal;
      outer = (
        <View spacing={horizontal ? 20 : 8} row={horizontal}>
          {question.sublabel ? <Text text={question.sublabel} weight="semibold" /> : null}
          {question?.choices?.map(({ label, value }) => {
            return (
              <Choice
                testID={`${testID}__choice__${value}`}
                key={value}
                depth={depth}
                question={question}
                label={label}
                answer={answer}
                value={value}
                onChangeAnswer={onChangeAnswer}
              />
            );
          })}
        </View>
      );
      break;
    case 'text':
      const currentValue = (answer?.value as string[]) ?? [''];
      outer = (
        <View spacing={8}>
          {currentValue.map((value, i, arr) => (
            <TextInput
              testID={testID}
              key={`${i}-${arr.length}`}
              label={question.sublabel}
              value={value}
              onChangeValue={(v) => {
                onChangeAnswer({
                  value: produce(currentValue, (draft) => {
                    if (v || draft.length === 1) {
                      draft[i] = v;
                    } else {
                      draft.splice(i, 1);
                    }
                  }),
                });
              }}
              placeholder={
                question.placeholder ??
                $t({ id: 'SchemaForm_describePlaceholder', defaultMessage: 'Describe' })
              }
            />
          ))}
          {question.other ? (
            <Button
              variant="text"
              text={$t({ id: 'SchemaForm_addAnotherButton', defaultMessage: '+ Add another' })}
              onPress={() => {
                onChangeAnswer({ value: [...(answer?.value ?? []), ''] as string[] });
              }}
            />
          ) : null}
        </View>
      );
      break;
    case 'yesno':
      const value = answer?.value?.toString() || '';
      outer = (
        <>
          {question.sublabel ? <Text text={question.sublabel} weight="semibold" /> : null}
          <View spacing={8}>
            <Checkbox
              testID={`${testID}__choice__${true}`}
              label={$t({ id: 'SchemaForm_choices_yes', defaultMessage: 'Yes' })}
              variant="radio"
              horizontal
              value={answer?.value?.[0] === true}
              onChangeValue={() => onChangeAnswer({ value: [true] })}
            />
            <Checkbox
              testID={`${testID}__choice__${false}`}
              label={$t({ id: 'SchemaForm_choices_no', defaultMessage: 'No' })}
              variant="radio"
              horizontal
              value={answer?.value?.[0] === false}
              onChangeValue={() => onChangeAnswer({ value: [false] })}
            />
            {question.followups?.[value] ? (
              <Followups
                testIDPrefix={`${testID}__choice__${value}`}
                depth={depth + 1}
                questions={question.followups?.[value] || []}
                answers={answer?.followups?.[value] || []}
                onChangeAnswers={(newAnswers) => {
                  const followups = produce(answer?.followups ?? {}, (draft) => {
                    if (!draft[value]) draft[value] = [];
                    draft[value] = newAnswers;
                  });
                  onChangeAnswer({ followups });
                }}
              />
            ) : null}
          </View>
        </>
      );
      break;
  }

  const inner = (
    <View spacing={10}>
      {question.label ? <Text text={question.label} size={24} weight="semibold" /> : null}
      {outer}
    </View>
  );

  return sequenceNumber ? (
    <View row spacing={10} style={{ alignItems: 'flex-start' }}>
      <Text
        text={sequenceNumber}
        size={24}
        weight="semibold"
        style={{ minWidth: 34 }}
        textAlign="right"
      />
      <View flex={1}>{inner}</View>
    </View>
  ) : (
    inner
  );
}, isEqual);

function RootQuestion({
  testID,
  depth,
  question,
  sequenceNumber,
  answer,
  onChangeValue,
}: QuestionProps) {
  const memoChangeValue = useCallback(onChangeValue, []); // eslint-disable-line
  return (
    <Question
      testID={testID}
      depth={depth}
      sequenceNumber={sequenceNumber}
      question={question}
      onChangeValue={memoChangeValue}
      answer={answer}
    />
  );
}

function getTextSummaryForQuestion(
  $t: IntlShape['$t'],
  question: Question,
  answer: Answer,
): string {
  if (!answer) return '';

  function renderFollowups(key: string) {
    return (
      answer.followups?.[key]
        ?.map((followupAnswer, i) => {
          return getTextSummaryForQuestion($t, question.followups![key][i], followupAnswer);
        })
        .join('\n') ?? ''
    );
  }

  switch (question.type) {
    case 'multichoice':
    case 'singlechoice':
      const choices = (answer.value as string[])
        ?.map((v) => {
          const label = question.choices.find((c) => c.value === v)?.label ?? '';
          const followups = renderFollowups(v);
          return `- ${label}${followups ? `\n${followups}` : ''}`;
        })
        .join('\n');

      return `${question.label || ''}\n${choices}\n`;
    case 'text':
      return `${question.label || question.sublabel || question.placeholder}: "${
        answer.value?.[0]
      }"\n`;
    case 'yesno':
      const result =
        answer?.value?.[0] === true
          ? $t({ id: 'SchemaForm_choices_yes', defaultMessage: 'Yes' })
          : answer?.value?.[0] === false
            ? $t({ id: 'SchemaForm_choices_no', defaultMessage: 'No' })
            : '';
      const followups = renderFollowups(answer?.value?.[0] as string);
      return `${question.label}\n- ${result}\n${followups ? `${followups}\n` : ''}`;
  }
}

export function getTextSummaryForForm(
  $t: IntlShape['$t'],
  schema: Schema,
  answers: FormAnswers,
): string {
  return Object.entries(schema)
    .sort(([, a], [, b]) => ((a.index ?? 0) < (b.index ?? 0) ? -1 : 1))
    .map(([key, question], i) => {
      return getTextSummaryForQuestion($t, question, answers[key]?.[0]);
    })
    .join('\n');
}

export function useRenderForm(
  getSchema: (formData: FormAnswers) => Schema,
  defaultValue?: FormAnswers,
): { data: FormAnswers; form: ReactNode } {
  const { data, bind } = useForm(defaultValue ?? ({} as FormAnswers), {
    merger: (originalValue: any, formValue: any, key: string) => {
      if (Array.isArray(originalValue)) {
        return formValue;
      }
    },
  });

  const schema = getSchema(data);

  const form = (
    <View spacing={40}>
      {Object.entries(schema)
        .sort(([, a], [, b]) => ((a.index ?? 0) < (b.index ?? 0) ? -1 : 1))
        .map(([key, question], i) => {
          const { value, onChangeValue } = bind([key], { label: question.label });
          return (
            <RootQuestion
              depth={0}
              testID={`SchemaForm_${key}`}
              key={key}
              sequenceNumber={`${i + 1}.`}
              question={question}
              onChangeValue={(a) => onChangeValue([a])}
              answer={value?.[0]}
            />
          );
        })}
    </View>
  );

  return { data, form };
}
