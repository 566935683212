import { useMutation, useQuery } from '@apollo/client';
import pickBy from 'lodash/pickBy';
import { useCallback, useLayoutEffect } from 'react';
import { Linking } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { AuthenticationSettings } from '@oui/app-core/src/components/AuthenticationSettings';
import { type CurrentUserQueryName } from '@oui/app-core/src/hooks/useCurrentUser';
import { useLogout } from '@oui/app-core/src/hooks/useLogout';
import { parseGQLDateTime } from '@oui/lib/src/gqlDate';
import { graphql, VariablesOf } from '@oui/lib/src/graphql/tada';
import states from '@oui/lib/src/metadata/states.json';

import { Button } from '@src/components/Button';
import DateTimeInput from '@src/components/DateTimeInput';
import Divider from '@src/components/Divider';
import { ErrorPresenter } from '@src/components/ErrorPresenter';
import { HeaderButtons, HeaderItem } from '@src/components/HeaderButtons';
import PickerInput from '@src/components/PickerInput';
import { ScrollView } from '@src/components/ScrollView';
import { Heading, Text } from '@src/components/Text';
import TextInput from '@src/components/TextInput';
import { UserAddress } from '@src/components/UserAddress';
import { View } from '@src/components/View';
import { APP_SLUG, manifest } from '@src/constants';
import { useForm } from '@src/hooks/useForm';
import { useI18n } from '@src/lib/i18n';
import { logEvent } from '@src/lib/log';
import { useTheme } from '@src/styles';
import { StackScreenProps } from '@src/types';
import { namedAvivaOperations } from '@src/types/namedOperations.generated';

type Props = StackScreenProps<'AccountSettings'>;

const AccountSettingsQuery = graphql(`
  query AccountSettings {
    ouiUser {
      __typename
      userID
      primaryOrganization {
        __typename
        ID
        isTrialOrganization
      }
    }
    currentUser {
      __typename
      ID
      email
      phone
      name {
        first
        last
        preferred
        pfx
        sfx
      }
      demo {
        DOB
      }
      address {
        city
        country
        line1
        line2
        zip
        state
      }
    }
  }
`);

const SaveAccountSettingsMutation = graphql(`
  mutation SaveAccountSettings(
    $name: UserNameInput
    $address: UserAddressInput
    $demo: UserDemoInput
    $phone: String
  ) {
    updateInfo(name: $name, address: $address, demo: $demo, phone: $phone)
  }
`);

const IFU_URL =
  'https://storage.googleapis.com/asset.oui.dev/static/InstructionsforUse_23July2021.pdf';

function Subheading(props: { text: string }) {
  const { Color } = useTheme();
  return (
    <Text
      text={props.text}
      color={Color.styleGuide.Gray3}
      style={{ marginBottom: 6 }}
      weight="semibold"
    />
  );
}

export default function AccountSettings(props: Props) {
  const { $t, formatDate } = useI18n();
  const { data } = useQuery(AccountSettingsQuery);
  const [updateUser] = useMutation(SaveAccountSettingsMutation);
  const logout = useLogout();
  const insets = useSafeAreaInsets();

  const {
    clear,
    bind: bind,
    data: formData,
    validate,
    humanErrors,
  } = useForm<VariablesOf<typeof SaveAccountSettingsMutation>>(
    data && data.currentUser
      ? {
          name: {
            first: data.currentUser.name.first,
            last: data.currentUser.name.last,
            preferred: data.currentUser.name.preferred,
            pfx: data.currentUser.name.pfx,
            sfx: data.currentUser.name.sfx,
          },
          demo: data.currentUser.demo,
          phone: data.currentUser.phone,
          address: {
            line1: data.currentUser.address?.line1 ?? '',
            line2: data.currentUser.address?.line2 ?? '',
            city: data.currentUser.address?.city ?? '',
            state: data.currentUser.address?.state ?? '',
            country: '', // unused field, but required
            zip: data.currentUser.address?.zip ?? '',
          },
        }
      : null,
  );
  const isEditing = props.route.params?.isEditing === 'true';
  const onSave = useCallback(
    async (overrides?: Partial<VariablesOf<typeof SaveAccountSettingsMutation>>) => {
      if (validate()) {
        logEvent('update_account');
        await updateUser({
          variables: { ...formData, ...overrides },
          refetchQueries: [
            namedAvivaOperations.Query.AccountSettings,
            'CurrentUser' satisfies CurrentUserQueryName,
          ],
        });
        props.navigation.setParams({ isEditing: 'false' });
      }
    },
    [validate, updateUser, formData, props.navigation],
  );

  const navigation = props.navigation;
  useLayoutEffect(() => {
    const options: Parameters<typeof navigation.setOptions>[0] = {
      headerTitle: isEditing
        ? $t({ id: 'AccountSettings_editHeading', defaultMessage: 'Edit account settings' })
        : $t({ id: 'AccountSettings_heading', defaultMessage: 'Account settings' }),
      headerRight: ({ tintColor }) => (
        <HeaderButtons>
          {isEditing ? (
            <Button
              text={$t({ id: 'AccountSettings_saveButton', defaultMessage: 'Save' })}
              testID="AccountSettings_saveButton"
              alignSelf="flex-start"
              onPress={onSave}
              style={{ paddingHorizontal: 14 }}
            />
          ) : (
            <HeaderItem
              accessibilityLabel={$t({ id: 'AccountSettings_editButton', defaultMessage: 'Edit' })}
              title=""
              onPress={() => navigation.setParams({ isEditing: 'true' })}
              iconName="edit"
              color={tintColor}
              testID="AccountSettings_editButton"
            />
          )}
        </HeaderButtons>
      ),
    };

    if (isEditing) {
      options.headerLeft = ({ tintColor }) => (
        <HeaderButtons>
          <HeaderItem
            accessibilityLabel={$t({
              id: 'AccountSettings_cancelButton',
              defaultMessage: 'Cancel',
            })}
            title=""
            onPress={() => {
              clear();
              navigation.setParams({ isEditing: 'false' });
            }}
            iconName="close"
            color={tintColor}
          />
        </HeaderButtons>
      );
    } else {
      // if this screen is in a tab navigator, we don't want to show a back button because
      // that will be handled by the grandparent navigator which isn't what we want. Rather, if the
      // user wants to leave this screen, they should press a different tab
      if (navigation.getParent()?.getState().type === 'tab') {
        options.headerLeft = () => null;
      } else {
        options.headerLeft = undefined;
      }
    }

    navigation.setOptions(options);
  }, [navigation, isEditing, clear, onSave, $t]);

  return (
    <ScrollView
      contentContainerStyle={{ flexGrow: 1, padding: 20, paddingBottom: 20 + insets.bottom }}
      testID="AccountSettings_scrollView"
    >
      <View spacing={16}>
        {isEditing ? <ErrorPresenter formErrors={humanErrors} /> : null}
        <Heading
          text={$t({ id: 'AccountSettings_accountHeading', defaultMessage: 'Account' })}
          level={2}
        />
        {isEditing ? (
          <View spacing={8}>
            <Divider />
            <TextInput
              {...bind(['name', 'first'], {
                validator: { type: 'present' },
                accessibilityLabel: $t({
                  id: 'AccountSettings_givenNameLabel',
                  defaultMessage: 'First name',
                }),
              })}
              placeholder={$t({
                id: 'AccountSettings_givenNameLabel',
                defaultMessage: 'First name',
              })}
              disabled={!isEditing}
            />
            <TextInput
              {...bind(['name', 'last'], {
                validator: { type: 'present' },
                accessibilityLabel: $t({
                  id: 'AccountSettings_familyNameLabel',
                  defaultMessage: 'Last name',
                }),
              })}
              placeholder={$t({
                id: 'AccountSettings_familyNameLabel',
                defaultMessage: 'Last name',
              })}
              disabled={!isEditing}
            />
          </View>
        ) : data && data.currentUser ? (
          <View spacing={20}>
            <>
              <Subheading text={$t({ id: 'AccountSettings_nameLabel', defaultMessage: 'Name' })} />
              <Text
                testID="AccountSettings_name"
                text={`${data.currentUser.name.preferred || data.currentUser.name.first} ${
                  data.currentUser.name.last
                }`}
              />
            </>
            <>
              <Subheading
                text={$t({ id: 'AccountSettings_emailLabel', defaultMessage: 'Email' })}
              />
              <Text text={data.currentUser.email} />
            </>
            {isEditing || APP_SLUG === 'oui-aviva-staff' ? null : (
              <>
                <Subheading
                  text={$t({
                    id: 'AccountSettings_authenticationHeading',
                    defaultMessage: 'Authentication',
                  })}
                />
                <AuthenticationSettings />
              </>
            )}
          </View>
        ) : null}
        <Heading
          text={$t({
            id: 'AccountSettings_personalInformationHeading',
            defaultMessage: 'Personal information',
          })}
          level={2}
          style={{ marginTop: 40 }}
        />
        {isEditing ? (
          <View spacing={8}>
            <Divider />
            <>
              <Text
                accessibilityRole="none"
                text={$t({ id: 'AccountSettings_addressLabel', defaultMessage: 'Address' })}
                style={{ marginLeft: 16, marginBottom: 5 }}
                weight="semibold"
              />
              <TextInput
                {...bind(['address', 'line1'], {
                  accessibilityLabel: $t({
                    id: 'AccountSettings_addressLine1Label',
                    defaultMessage: 'Street address',
                  }),
                })}
                placeholder={$t({
                  id: 'AccountSettings_addressLine1Label',
                  defaultMessage: 'Street address',
                })}
              />
              <TextInput
                {...bind(['address', 'line2'], {
                  accessibilityLabel: $t({
                    id: 'AccountSettings_addressLine2Label',
                    defaultMessage: 'Address Line 2',
                  }),
                })}
                placeholder={$t({
                  id: 'AccountSettings_addressLine2Label',
                  defaultMessage: 'Address Line 2',
                })}
              />
              <View row spacing={12} style={{ flexWrap: 'wrap' }}>
                <TextInput
                  placeholder={$t({
                    id: 'AccountSettings_addressCityLabel',
                    defaultMessage: 'City',
                  })}
                  {...bind(['address', 'city'], {
                    accessibilityLabel: $t({
                      id: 'AccountSettings_addressCityLabel',
                      defaultMessage: 'City',
                    }),
                  })}
                  style={{ width: 200 }}
                />
                <PickerInput
                  {...bind(['address', 'state'], {
                    accessibilityLabel: $t({
                      id: 'AccountSettings_addressStateLabel',
                      defaultMessage: 'State',
                    }),
                  })}
                  items={states.map((s) => ({ label: s.name, value: s.abbreviation }))}
                  style={{ width: 150 }}
                />
                <TextInput
                  {...bind(['address', 'zip'], {
                    accessibilityLabel: $t({
                      id: 'AccountSettings_addressZipLabel',
                      defaultMessage: 'Zip code',
                    }),
                  })}
                  placeholder={$t({
                    id: 'AccountSettings_addressZipLabel',
                    defaultMessage: 'Zip code',
                  })}
                  style={{ width: 100 }}
                />
              </View>
            </>
            <TextInput
              {...bind('phone', {
                label: $t({
                  id: 'AccountSettings_phoneLabel',
                  defaultMessage: 'Phone number',
                }),
              })}
              autoComplete="tel"
              disabled={!isEditing}
              keyboardType="phone-pad"
              placeholder={$t({
                id: 'AccountSettings_phonePlaceholder',
                defaultMessage: 'Phone number',
              })}
              textContentType="telephoneNumber"
            />
            <DateTimeInput
              label={$t({
                id: 'AccountSettings_dateOfBirthLabel',
                defaultMessage: 'Date of birth',
              })}
              {...bind(['demo', 'DOB'], {
                label: $t({
                  id: 'AccountSettings_dateOfBirthLabel',
                  defaultMessage: 'Date of birth',
                }),
              })}
              placeholder={$t({
                id: 'AccountSettings_dateOfBirthPlaceholder',
                defaultMessage: 'MM / DD / YYYY',
              })}
              mode="date"
            />
          </View>
        ) : data && data.currentUser ? (
          <View spacing={20}>
            {data?.currentUser.address && Object.keys(pickBy(data.currentUser.address)).length ? (
              <>
                <Subheading
                  text={$t({ id: 'AccountSettings_addressLabel', defaultMessage: 'Address' })}
                />
                <UserAddress {...data.currentUser.address} />
              </>
            ) : null}
            <>
              <Subheading
                text={$t({ id: 'AccountSettings_phoneLabel', defaultMessage: 'Phone number' })}
              />
              <Text
                text={
                  data.currentUser.phone ||
                  $t({ id: 'AccountSettings_unknownValue', defaultMessage: 'Unknown' })
                }
              />
            </>
            <>
              <Subheading
                text={$t({
                  id: 'AccountSettings_dateOfBirthLabel',
                  defaultMessage: 'Date of birth',
                })}
              />
              <Text
                text={
                  data.currentUser.demo?.DOB
                    ? formatDate(parseGQLDateTime(data.currentUser.demo.DOB), {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                      })
                    : $t({ id: 'AccountSettings_unknownValue', defaultMessage: 'Unknown' })
                }
              />
            </>
          </View>
        ) : null}
      </View>
      <View style={{ flex: 1 }} />
      {APP_SLUG !== 'oui-aviva' || isEditing ? null : (
        <>
          <Heading
            text={$t({ id: 'AccountSettings_legalHeading', defaultMessage: 'Legal' })}
            level={2}
            style={{ marginTop: 40 }}
          />
          <View spacing={8}>
            <Button
              variant="text"
              text={$t({
                id: 'AccountSettings_termsOfServiceLink',
                defaultMessage: 'Terms of service & privacy policy',
              })}
              onPress={() => {
                props.navigation.navigate('TermsAndPrivacy');
              }}
            />
            {data?.ouiUser?.primaryOrganization?.isTrialOrganization ? (
              <>
                <Button
                  variant="text"
                  text={$t({
                    id: 'AccountSettings_instructionsForUseLink',
                    defaultMessage: 'Instructions for use',
                  })}
                  onPress={() => {
                    Linking.openURL(IFU_URL);
                  }}
                />
                <Text
                  text={$t({
                    id: 'AccountSettings_investigationalDevice',
                    defaultMessage:
                      'CAUTION—Investigational device. Limited by Federal law to investigational use.',
                  })}
                />
                <Text
                  text={$t(
                    {
                      id: 'AccountSettings_investigationalDeviceVersion',
                      defaultMessage: 'Version: {version}',
                    },
                    { version: manifest.version },
                  )}
                />
              </>
            ) : null}
          </View>
        </>
      )}
      {isEditing ? null : (
        <Button
          style={{ marginTop: 40 }}
          text={$t({
            id: 'AccountSettings_logOutLink',
            defaultMessage: 'Log out',
          })}
          variant="text"
          onPress={logout}
        />
      )}
    </ScrollView>
  );
}
