import { useFocusEffect, useNavigation } from '@react-navigation/native';
import hexToRgba from 'hex-to-rgba';
import { useCallback, useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { useAppContext } from '@oui/app-core/src/components/AppContext';
import { CheckboxListInput } from '@oui/app-core/src/components/CheckboxListInput';
import { formatRelativeTime } from '@oui/lib/src/formatRelativeTime';
import { OnboardingVariant, OuiUserRoleType } from '@oui/lib/src/types/graphql.generated';
import { DeepWriteable, GQLUUID } from '@oui/lib/src/types/scalars';

import { Accordion, ButtonAccordion } from '@src/components/Accordion';
import { ActivityIndicator } from '@src/components/ActivityIndicator';
import { Button } from '@src/components/Button';
import Checkbox from '@src/components/Checkbox';
import { ErrorPresenter } from '@src/components/ErrorPresenter';
import { FormModal } from '@src/components/FormModal';
import { Icon } from '@src/components/Icon';
import { LinkButton } from '@src/components/LinkButton';
import { OrganizationMemberActionPopupMenu } from '@src/components/OrganizationMemberActionPopupMenu';
import PickerInput from '@src/components/PickerInput';
import { PlatformScreen } from '@src/components/PlatformScreen';
import { PlatformTabs } from '@src/components/PlatformTabs';
import { ListItemStyle, StaffTable, TableCell } from '@src/components/StaffTable';
import { Heading, Text } from '@src/components/Text';
import { EmailInput, TextInput } from '@src/components/TextInput';
import { View } from '@src/components/View';
import { APP_SLUG, manifest, REST_API_URL } from '@src/constants';
import { useCurrentUser } from '@src/hooks/useCurrentUser';
import { useForm } from '@src/hooks/useForm';
import { getAuthToken } from '@src/lib/auth';
import { getPickerItemsFromEnum, humanizeEnumKey } from '@src/lib/getPickerItemsFromEnum';
import {
  DEMOGRAPHIC_LABELS,
  INTERVENTION_LABELS,
  listDemographicsForProductVariants,
  listPossibleInterventions,
} from '@src/lib/product';
import { useTheme } from '@src/styles';
import { CliniciansScreenProps } from '@src/types';
import { namedAvivaOperations } from '@src/types/namedOperations.generated';

import {
  AddOrganizationMemberMutationVariables,
  AdminOrganizationQuery,
  useAddOrganizationMemberMutation,
  useAdminOrganizationQuery,
} from './Organization.graphql.generated';

export function useOrganization(organizationID: GQLUUID) {
  return useAdminOrganizationQuery({
    variables: { organizationID },
  });
}

const ExportTypeEnum = {
  Sessions: 'Sessions',
  Patients: 'Patients',
  Screeners: 'Screeners',
} as const;
type ExportTypeEnum = (typeof ExportTypeEnum)[keyof typeof ExportTypeEnum];

const REGISTRAR_COLOR = '#1E59A9';
const PRACTITIONER_COLOR = '#1B9FA0';

const AddOrganizationMemberModal = ({
  organizationID,
  onRequestClose,
}: {
  organizationID: GQLUUID;
  onRequestClose: () => void;
}) => {
  const { bind, data, validate } = useForm<DeepWriteable<AddOrganizationMemberMutationVariables>>({
    organizationID,
    person: { email: '', givenName: '', familyName: '' },
    roles: [],
  });

  const [addMember, { error }] = useAddOrganizationMemberMutation();

  const onAdd = async () => {
    if (validate()) {
      await addMember({
        variables: data,
        refetchQueries: [namedAvivaOperations.Query.AdminOrganization],
      });
      onRequestClose();
    }
  };

  return (
    <FormModal
      minWidth={600}
      visible
      onCancel={onRequestClose}
      onConfirm={onAdd}
      confirmTestID="AddOrganizationMemberModal_confirmButton"
      title="Add new organization member"
      subtitle="Parent org members also have access and privileges in all sub-orgs"
    >
      <View spacing={25}>
        {error ? <ErrorPresenter error={error} /> : null}
        <View row childFlex={1} spacing={20}>
          <TextInput
            {...bind(['person', 'givenName'], {
              label: 'First name*',
              validator: { type: 'present' },
            })}
            placeholder="Christina"
          />
          <TextInput
            {...bind(['person', 'familyName'], {
              label: 'Last name*',
              validator: { type: 'present' },
            })}
            placeholder="Smith"
          />
        </View>
        <EmailInput
          {...bind(['person', 'email'], { label: 'Email*', validator: { type: 'present' } })}
          placeholder="email@domain.com"
        />
        <CheckboxListInput
          {...bind('roles', {
            label: 'Role*',
            validator: (val) => {
              if (val.length === 0) {
                return 'Must select at least one role';
              }
              return undefined;
            },
          })}
          labelSize={16}
          hideHint
          items={{
            [OuiUserRoleType.REGISTRAR]: 'Admin',
            [OuiUserRoleType.PRACTITIONER]: 'Practitioner',
          }}
        />
      </View>
    </FormModal>
  );
};

const VariantDetails = ({
  heading,
  content = [''],
}: {
  heading: string;
  content: ReadonlyArray<string>;
}) => {
  const { Color } = useTheme();
  return (
    <View spacing={10}>
      <Text text={heading} weight="semibold" color={Color.styleGuide.Gray2} />
      {content.map((item) => {
        return <Text key={item} text={item} />;
      })}
    </View>
  );
};

const RoleBadge = ({ roles }: { roles: ReadonlyArray<OuiUserRoleType> }) => {
  return (
    <>
      {roles
        ? roles.map((role) => {
            return (
              <View
                key={role}
                style={{
                  backgroundColor:
                    role === OuiUserRoleType.REGISTRAR
                      ? hexToRgba(REGISTRAR_COLOR, 0.05)
                      : hexToRgba(PRACTITIONER_COLOR, 0.05),
                  borderColor:
                    role === OuiUserRoleType.REGISTRAR ? REGISTRAR_COLOR : PRACTITIONER_COLOR,
                  borderWidth: 1,
                  borderRadius: 5,
                  paddingVertical: 5,
                  paddingHorizontal: 10,
                  marginRight: 10,
                }}
              >
                <Text
                  color={role === OuiUserRoleType.REGISTRAR ? '#16417C' : '#127071'}
                  size={15}
                  text={role === OuiUserRoleType.REGISTRAR ? 'Admin' : humanizeEnumKey(role)}
                  weight="semibold"
                />
              </View>
            );
          })
        : null}
    </>
  );
};

const OrganizationHierarchy = ({
  organization,
  parentOrg,
  currentUser,
}: {
  organization: OrganizationByID;
  parentOrg: OrganizationByID | ParentOrganizationType;
  currentUser: CurrentUserType;
}) => {
  const { Color } = useTheme();
  return (
    <View
      style={{
        marginLeft: 32,
      }}
      flex={1}
    >
      <Text
        text="Organization type"
        color={Color.styleGuide.BrandSpaceBlue}
        weight="bold"
        size={17}
        style={{ marginBottom: 15 }}
      />
      {organization.parent === null ? (
        <View row>
          <Icon
            name="parent-organization"
            style={{ paddingRight: 5 }}
            color={Color.styleGuide.Gray5}
            size={22}
          />
          <Text text="Parent organization" weight="semibold" size={15} />
        </View>
      ) : (
        <View row>
          <Icon name="child-organization" color={Color.styleGuide.Gray5} />
          <Text text="" weight="semibold" size={15}>
            Sub organization of{' '}
            <LinkButton to={`/organizations/${organization.parent?.ID}`}>
              <Text text={organization.parent?.name!} color={Color.accent} weight="semibold" />
            </LinkButton>
          </Text>
        </View>
      )}
      {(parentOrg?.children?.length || 0 > 0) &&
      /* OUI_ADMIN is deprecated in favor of attributes.admin */
      (currentUser.roles.find((role) => role.role === OuiUserRoleType.OUI_ADMIN) ||
        currentUser.attributes.admin ||
        currentUser.roles.find((r) => r.organization?.ID === parentOrg?.ID)) ? (
        <ButtonAccordion
          text="All sub-organizations"
          style={{ marginLeft: 30 }}
          color={Color.styleGuide.Gray1}
        >
          <View style={{ marginLeft: 15 }}>
            {parentOrg?.children?.map((suborg) => {
              return (
                <View row key={suborg.ID} style={{ marginBottom: 12 }}>
                  <Icon
                    name="child-organization"
                    style={{
                      marginLeft: 10,
                    }}
                    color={Color.styleGuide.Gray5}
                  />
                  <LinkButton
                    to={`/organizations/${suborg.ID}`}
                    disabled={organization.ID === suborg.ID}
                  >
                    <Text
                      text={suborg.name}
                      color={
                        organization.ID === suborg.ID
                          ? Color.styleGuide.Gray3
                          : Color.styleGuide.BrandCarminePink
                      }
                      weight="semibold"
                    />
                  </LinkButton>
                </View>
              );
            })}
          </View>
        </ButtonAccordion>
      ) : null}
    </View>
  );
};

const OrganizationProductConfiguration = ({ organization }: { organization: OrganizationByID }) => {
  const { Color } = useTheme();

  const possibleInterventions = listPossibleInterventions(organization.productVariants || []);
  return (
    <View flex={1}>
      <View
        style={{
          justifyContent: 'flex-start',
        }}
      >
        <Text
          text="Products"
          color={Color.styleGuide.BrandSpaceBlue}
          weight="bold"
          size={17}
          style={{ marginBottom: 15 }}
        />
      </View>
      {possibleInterventions.map((intervention) => (
        <Accordion
          key={intervention}
          heading={<Text text={INTERVENTION_LABELS[intervention]} weight="semibold" />}
          expandedHeadingStyle={{
            borderBottomWidth: 1,
            borderBottomColor: Color.styleGuide.Gray6,
            paddingBottom: 10,
          }}
          content={
            <View
              style={{
                paddingHorizontal: 46,
              }}
              spacing={26}
            >
              <View spacing={26} row style={{ alignItems: 'flex-start' }}>
                <View flex={1}>
                  <VariantDetails
                    heading="Demographic"
                    content={listDemographicsForProductVariants(
                      organization.productVariants ?? [],
                    ).map((demo) => DEMOGRAPHIC_LABELS[demo])}
                  />
                </View>
                <View flex={1}>
                  <VariantDetails
                    heading="Onboarding type"
                    content={[
                      organization.onboardingVariant
                        ? humanizeEnumKey(OnboardingVariant[organization.onboardingVariant])
                        : '',
                    ]}
                  />
                </View>
              </View>
              <View>
                {organization.caringContactsEnabled ? (
                  <VariantDetails
                    heading="Caring Contacts"
                    content={['Enabled for US-based patients']}
                  />
                ) : null}
              </View>
            </View>
          }
        />
      ))}
    </View>
  );
};

const OrganizationDataExport = ({ organization }: { organization: OrganizationByID }) => {
  const { Color } = useTheme();
  const [includeSuborgs, setIncludeSuborgs] = useState(false);
  const [exportType, setExportType] = useState<ExportTypeEnum>();

  return (
    <View
      style={{
        marginLeft: 32,
      }}
    >
      <View>
        <Text
          text="Export Data"
          color={Color.styleGuide.BrandSpaceBlue}
          weight="bold"
          size={17}
          style={{ marginBottom: 12 }}
        />
      </View>
      <View row>
        <PickerInput
          items={getPickerItemsFromEnum(ExportTypeEnum)}
          value={exportType}
          onChangeValue={setExportType}
          placeholder="Choose export"
        />
        <View
          style={{
            paddingHorizontal: 14,
          }}
        >
          {organization.parent === null ? (
            <Checkbox
              onChangeValue={setIncludeSuborgs}
              value={includeSuborgs}
              horizontal
              label="Include suborgs?"
            />
          ) : null}
        </View>
      </View>
      <Button
        text="Export"
        iconRight="download"
        onPress={async () => {
          if (exportType) {
            await getOrganizationExportData(organization.ID, includeSuborgs, exportType);
          }
        }}
        disabled={!exportType}
        alignSelf="center"
      />
    </View>
  );
};

type OrganizationByID = NonNullable<AdminOrganizationQuery['organizationByID']>;
type ParentOrganizationType = OrganizationByID['parent'];
type CurrentUserType = NonNullable<
  NonNullable<ReturnType<typeof useCurrentUser>['data']>['currentUser']
>;

const OrganizationDetails = ({
  organization,
  currentUser,
  canExportData,
}: {
  organization: OrganizationByID;
  currentUser: CurrentUserType;
  canExportData: boolean;
}) => {
  const parentOrg = organization.parent === null ? organization : organization.parent;

  return (
    <>
      <View
        style={{
          backgroundColor: 'white',
          borderRadius: 8,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          padding: 8,
          paddingTop: 24,
        }}
      >
        <OrganizationHierarchy
          parentOrg={parentOrg}
          organization={organization}
          currentUser={currentUser}
        />
        <OrganizationProductConfiguration organization={organization} />
      </View>
      <View
        style={{
          backgroundColor: 'white',
          borderRadius: 8,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          padding: 8,
          paddingTop: 12,
        }}
      >
        {canExportData ? <OrganizationDataExport organization={organization} /> : null}
      </View>
    </>
  );
};

type Anchor = 'all' | 'admin' | 'practitioner' | undefined;

const OrganizationMembers = ({
  organization,
  anchor,
  loading,
  organizationID,
}: {
  organization: OrganizationByID;
  anchor: Anchor;
  loading: boolean;
  organizationID: GQLUUID;
}) => {
  const { Color } = useTheme();
  const navigation = useNavigation<CliniciansScreenProps<'Organization'>['navigation']>();
  anchor = anchor ?? ('all' as Anchor)!;

  const [showAddMemberModal, setShowAddMemberModal] = useState(false);

  const displayMembers = useMemo(() => {
    let allMembers = [...(organization.members || [])];

    if (anchor === 'all') {
      return allMembers;
    } else if (anchor === 'admin') {
      return organization.members.filter((org) => org.roles.includes(OuiUserRoleType.REGISTRAR));
    } else {
      return organization.members.filter((org) => org.roles.includes(OuiUserRoleType.PRACTITIONER));
    }
  }, [anchor, organization.members]);

  const { locale } = useAppContext();

  return (
    <>
      {showAddMemberModal ? (
        <AddOrganizationMemberModal
          organizationID={organizationID}
          onRequestClose={() => setShowAddMemberModal(false)}
        />
      ) : null}
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingHorizontal: 12,
          paddingBottom: 12,
          flexWrap: 'wrap',
        }}
      >
        <View
          style={{
            marginBottom: 24,
          }}
        >
          <Heading text="Organization members" level={2} />
          <Text
            text="All parent org members also have access and privileges in all sub-orgs"
            color={Color.styleGuide.Gray3}
            size={15}
          />
        </View>
        <Button
          icon="plus"
          onPress={() => setShowAddMemberModal(true)}
          variant="contained"
          text="Add member"
          testID="Organization_addMemberButton"
        />
      </View>
      <PlatformTabs
        value={anchor}
        onChangeValue={(value) => {
          navigation.setParams({ anchor: value });
        }}
        items={[
          {
            text: 'All',
            count: organization.stats.registrarCount + organization.stats.practitionerCount || 0,
            value: 'all',
          },
          {
            text: 'Admin',
            count: organization.stats.registrarCount || 0,
            value: 'admin',
          },
          {
            text: 'Practitioner',
            count: organization.stats.practitionerCount || 0,
            value: 'practitioner',
          },
        ]}
        style={{ marginBottom: 18 }}
      />
      <StaffTable
        columns={[
          'Name',
          'Role',
          { label: 'Email address', grow: 1.5 },
          { label: 'Last login', grow: 0.5 },
          { label: '', grow: 0, initialWidth: 'auto' },
        ]}
        loading={loading}
        testID="Organization_memberList"
        showNavigationChevron={false}
        renderItem={({ item }) => {
          return (
            <View style={ListItemStyle}>
              <TableCell>
                <Text
                  text={item.person.givenName + ' ' + item.person.familyName ?? 'Unknown'}
                  weight="semibold"
                />
              </TableCell>
              <TableCell>
                <RoleBadge roles={item.roles || []} />
              </TableCell>
              <TableCell grow={1.5}>
                <Text text={item.person.email ?? 'Unknown'} numberOfLines={1} />
              </TableCell>
              <TableCell grow={0.5}>
                {item.person.lastLogin ? (
                  <Text text={formatRelativeTime(locale, new Date(item.person.lastLogin))} />
                ) : (
                  <Text text={'Unknown'} />
                )}
              </TableCell>
              <TableCell grow={0} initialWidth="auto">
                <OrganizationMemberActionPopupMenu
                  organizationName={organization.name || ''}
                  organizationID={organizationID}
                  member={item}
                  isParent={organization.parent === null}
                />
              </TableCell>
            </View>
          );
        }}
        data={displayMembers}
        keyExtractor={(item) => item.userID}
      />
    </>
  );
};

export function Organization(props: CliniciansScreenProps<'Organization'>) {
  const { organizationID, anchor } = props.route.params;
  const { data, loading, refetch } = useAdminOrganizationQuery({
    variables: { organizationID: props.route.params.organizationID },
  });
  const { isAdmin, data: currentUser } = useCurrentUser();
  const canExportData =
    isAdmin ||
    !!currentUser?.currentUser?.roles.find(
      (r) => r.role === OuiUserRoleType.REGISTRAR && organizationID === r.organization?.ID,
    );

  useFocusEffect(
    useCallback(() => {
      refetch();
    }, [refetch]),
  );

  return (
    <PlatformScreen
      crumbs={[
        { label: 'Organizations', to: 'organizations' },
        { label: data?.organizationByID?.name ?? '', to: '' },
      ]}
      testID="Organization"
      innerTestID="Organization_mainScrollView"
    >
      {data?.organizationByID ? (
        <>
          <View style={{ marginBottom: 50 }}>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingHorizontal: 12,
                marginBottom: 24,
              }}
            >
              <Heading text={data?.organizationByID?.name || ''} level={1} />
              {isAdmin ? (
                <Button
                  size="small"
                  icon="edit"
                  onPress={() => props.navigation.navigate('EditOrganization', { organizationID })}
                  variant="text"
                  text="Edit org details"
                  testID="Organization_editOrgDetails"
                />
              ) : (
                <View />
              )}
            </View>
            {currentUser?.currentUser ? (
              <OrganizationDetails
                organization={data.organizationByID}
                currentUser={currentUser.currentUser}
                canExportData={canExportData}
              />
            ) : null}
          </View>
          <OrganizationMembers
            organization={data.organizationByID}
            anchor={anchor}
            loading={loading}
            organizationID={organizationID}
          />
        </>
      ) : (
        <View style={{ marginVertical: 20 }}>
          <ActivityIndicator />
        </View>
      )}
    </PlatformScreen>
  );
}

async function getOrganizationExportData(
  organizationID: GQLUUID,
  includeSubOrgs: boolean,
  exportType: ExportTypeEnum,
) {
  const urlSegment = {
    [ExportTypeEnum.Patients]: 'patients-progress',
    [ExportTypeEnum.Sessions]: 'patients-session-progress',
    [ExportTypeEnum.Screeners]: 'patients-screeners',
  }[exportType];

  const exportURL = `${REST_API_URL}/organization-export/${urlSegment}?organizationID=${organizationID}&includeSubOrgs=${includeSubOrgs}`;
  const requestID = uuid();
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getAuthToken()}`,
    'X-Oui-Client': APP_SLUG!,
    'X-RequestID': requestID,
    'X-Oui-Client-Version': manifest.version ?? '0.0.0',
  };
  const res = await fetch(exportURL, { method: 'GET', headers });
  const text = await res.text();
  const url = window.URL.createObjectURL(new Blob([text]));
  const link = document.createElement('a');
  link.href = url;
  const filename =
    res.headers
      // attachment; filename="foo.csv"
      .get('content-disposition')
      ?.split(';')
      .map((v) => v.trim().split('='))
      .find((v) => v[0] === 'filename')?.[1]
      .replace(/"/g, '') ?? 'download';
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
}
