import get from 'lodash/get';
import { ComponentProps, ReactElement, useCallback } from 'react';
import {
  Control,
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  FieldError,
  FieldPath,
  FieldValues,
  RegisterOptions,
  UseControllerProps,
  useFieldArray,
  useFormContext,
  UseFormReturn,
  UseFormStateReturn,
} from 'react-hook-form';
import { SetRequired } from 'type-fest';
import { z } from 'zod';

import { flattenErrorMessages } from '@oui/lib/src/flattenErrorMessages';
import { FormProvider } from '@oui/lib/src/form';
import { recordKeys } from '@oui/lib/src/recordKeys';
import { GQLDate, GQLDateTime, GQLTime } from '@oui/lib/src/types/scalars';

import { DateTimeInput, DateTimeInputProps } from './components/DateTimeInput';
import { ErrorPresenter } from './components/ErrorPresenter';
import { ImageInput } from './components/ImageInput';
import { MaxLengthTextInput } from './components/MaxLengthTextInput';
import { NumericStepperInput } from './components/NumericStepperInput/NumericStepperInput';
import { PickerInput } from './components/PickerInput';
import { RadioInput } from './components/RadioInput';
import { SegmentedControlInput } from './components/SegmentedControl';
import { SwitchInput } from './components/SwitchInput';
import { EmailInput, NumberInput, TextInput } from './components/TextInput';

export { useZodForm, useZodFormContext } from '@oui/lib/src/form';

type ControllerProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
> = SetRequired<
  Pick<UseControllerProps<TFieldValues, TName>, 'name' | 'control' | 'shouldUnregister'>,
  'name' | 'control'
>;

/*
 * Some of the input props we use have a discrimited union to safely accept label/accessibilityLabel
 * props in different combinations. That doesn't play nicely with built-in Omit
 * https://stackoverflow.com/questions/67794339/why-doesnt-discriminated-union-work-when-i-omit-require-props
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type DistributiveOmit<T, K extends PropertyKey> = T extends any ? Omit<T, K> : never;
export type InputRenderer<TComponentProps extends { value?: unknown; onChangeValue?: unknown }> = (
  renderProps: {
    field: Omit<
      ControllerRenderProps<FieldValues, FieldPath<FieldValues>>,
      'value' | 'onChange'
    > & {
      value: TComponentProps['value'];
      onChange: NonNullable<TComponentProps['onChangeValue']>;
    };
    fieldState: ControllerFieldState;
    formState: UseFormStateReturn<FieldValues>;
  },
  props: DistributiveOmit<TComponentProps, 'value' | 'onChangeValue'>,
) => ReactElement;

export const TextInputRender: InputRenderer<ComponentProps<typeof TextInput>> = (
  renderProps,
  props,
) => {
  const {
    field: { onChange, onBlur, value, name },
    fieldState: { error },
  } = renderProps;
  const formContext = useFormContext();
  const label =
    typeof props.label === 'string' && props.label ? props.label : props.accessibilityLabel;
  if (formContext && label) formContext.labels[name] = label;

  return (
    <TextInput
      testID={`FormInput_${name.replaceAll('.', '_')}`}
      onBlur={onBlur}
      onChangeValue={onChange}
      value={value}
      error={error?.message}
      {...props}
    />
  );
};

export function TextFormInput<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  control,
  name,
  shouldUnregister,
  ...rest
}: ControllerProps<TFieldValues, TName> & Parameters<typeof TextInputRender>[1]) {
  const validate: RegisterOptions<TFieldValues, TName>['validate'] = rest.required
    ? (v) => {
        const valid = z.string().min(1).safeParse(v);
        if (!valid.success) return 'Required';
        return;
      }
    : undefined;
  return (
    <Controller
      rules={{ validate }}
      name={name}
      control={control}
      shouldUnregister={shouldUnregister}
      render={(props) => TextInputRender(props, rest)}
    />
  );
}

export const MaxLengthTextInputRender: InputRenderer<ComponentProps<typeof MaxLengthTextInput>> = (
  renderProps,
  props,
) => {
  const {
    field: { onChange, onBlur, value, name },
    fieldState: { error },
  } = renderProps;
  const formContext = useFormContext();
  const label =
    typeof props.label === 'string' && props.label ? props.label : props.accessibilityLabel;
  if (formContext && label) formContext.labels[name] = label;

  return (
    <MaxLengthTextInput
      testID={`FormInput_${name.replaceAll('.', '_')}`}
      onBlur={onBlur}
      onChangeValue={onChange}
      value={value}
      error={error?.message}
      {...props}
    />
  );
};

export function MaxLengthTextFormInput<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  control,
  name,
  shouldUnregister,
  ...rest
}: ControllerProps<TFieldValues, TName> & Parameters<typeof MaxLengthTextInputRender>[1]) {
  const validate: RegisterOptions<TFieldValues, TName>['validate'] = rest.required
    ? (v) => {
        const valid = z.string().min(1).safeParse(v);
        if (!valid.success) return 'Required';
        return;
      }
    : undefined;
  return (
    <Controller
      rules={{ validate }}
      name={name}
      control={control}
      shouldUnregister={shouldUnregister}
      render={(props) => MaxLengthTextInputRender(props, rest)}
    />
  );
}

export const EmailInputRender: InputRenderer<ComponentProps<typeof EmailInput>> = (
  renderProps,
  props,
) => {
  const {
    field: { onChange, onBlur, value, name },
    fieldState: { error },
  } = renderProps;
  const formContext = useFormContext();
  const label =
    typeof props.label === 'string' && props.label ? props.label : props.accessibilityLabel;
  if (formContext && label) formContext.labels[name] = label;

  return (
    <EmailInput
      testID={`FormInput_${name.replaceAll('.', '_')}`}
      onBlur={onBlur}
      onChangeValue={onChange}
      value={value}
      error={error?.message}
      {...props}
    />
  );
};

export function EmailFormInput<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  control,
  name,
  shouldUnregister,
  ...rest
}: ControllerProps<TFieldValues, TName> & Parameters<typeof EmailInputRender>[1]) {
  const validate: RegisterOptions<TFieldValues, TName>['validate'] = rest.required
    ? (v) => {
        const valid = z.string().min(1).safeParse(v);
        if (!valid.success) return 'Required';
        return;
      }
    : undefined;
  return (
    <Controller
      rules={{ validate }}
      name={name}
      control={control}
      shouldUnregister={shouldUnregister}
      render={(props) => EmailInputRender(props, rest)}
    />
  );
}

export const PickerInputRender: InputRenderer<ComponentProps<typeof PickerInput>> = (
  renderProps,
  props,
) => {
  const {
    field: { onChange, onBlur, value, name },
    fieldState: { error },
  } = renderProps;
  const formContext = useFormContext();
  const label =
    typeof props.label === 'string' && props.label
      ? props.label
      : props.accessibilityLabel || props.placeholder;
  if (formContext && label) formContext.labels[name] = label;

  return (
    <PickerInput
      testID={`FormInput_${name.replaceAll('.', '_')}`}
      onClose={onBlur}
      onChangeValue={onChange}
      value={value}
      error={error?.message}
      {...props}
    />
  );
};

export function PickerFormInput<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  control,
  name,
  shouldUnregister,
  ...rest
}: ControllerProps<TFieldValues, TName> & Parameters<typeof PickerInputRender>[1]) {
  const validate: RegisterOptions<TFieldValues, TName>['validate'] = rest.required
    ? (v) => {
        if (!v && typeof v !== 'number') return 'Required';
        return;
      }
    : undefined;

  return (
    <Controller
      rules={{ validate }}
      name={name}
      control={control}
      shouldUnregister={shouldUnregister}
      render={(props) => PickerInputRender(props, rest)}
    />
  );
}

export const NumericStepperInputRender: InputRenderer<
  ComponentProps<typeof NumericStepperInput>
> = (renderProps, props) => {
  const {
    field: { onChange, value, name },
    fieldState: { error },
  } = renderProps;
  const formContext = useFormContext();
  const label =
    typeof props.label === 'string' && props.label ? props.label : props.accessibilityLabel;
  if (formContext && label) formContext.labels[name] = label;

  return (
    <NumericStepperInput
      testID={`FormInput_${name.replaceAll('.', '_')}`}
      onChangeValue={onChange}
      value={value}
      error={error?.message}
      {...props}
    />
  );
};

export function NumericStepperFormInput<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  control,
  name,
  shouldUnregister,
  ...rest
}: ControllerProps<TFieldValues, TName> & Parameters<typeof NumericStepperInputRender>[1]) {
  const validate: RegisterOptions<TFieldValues, TName>['validate'] = rest.required
    ? (v) => {
        if (!v) return 'Required';
        return;
      }
    : undefined;

  return (
    <Controller
      rules={{ validate }}
      name={name}
      control={control}
      shouldUnregister={shouldUnregister}
      render={(props) => NumericStepperInputRender(props, rest)}
    />
  );
}

export const DateTimeInputRender: InputRenderer<DateTimeInputProps<GQLDateTime>> = (
  renderProps,
  props,
) => {
  const {
    field: { onChange, onBlur, value, name },
    fieldState: { error },
  } = renderProps;
  const formContext = useFormContext();
  const label =
    typeof props.label === 'string' && props.label ? props.label : props.accessibilityLabel;
  if (formContext && label) formContext.labels[name] = label;
  return (
    <DateTimeInput
      testID={`FormInput_${name.replaceAll('.', '_')}`}
      mode="datetime"
      onChangeValue={(v) => {
        onChange(v);
        onBlur();
      }}
      value={value}
      error={error?.message}
      {...props}
    />
  );
};

export const DateInputRender: InputRenderer<DateTimeInputProps<GQLDate>> = (renderProps, props) => {
  const {
    field: { onChange, onBlur, value, name },
    fieldState: { error },
  } = renderProps;
  const formContext = useFormContext();
  const label =
    typeof props.label === 'string' && props.label ? props.label : props.accessibilityLabel;
  if (formContext && label) formContext.labels[name] = label;

  return (
    <DateTimeInput
      testID={`FormInput_${name.replaceAll('.', '_')}`}
      mode="date"
      onChangeValue={(v) => {
        onChange(v);
        onBlur();
      }}
      value={value}
      error={error?.message}
      {...props}
    />
  );
};

export function DateFormInput<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  control,
  name,
  shouldUnregister,
  ...rest
}: ControllerProps<TFieldValues, TName> & Parameters<typeof DateInputRender>[1]) {
  const validate: RegisterOptions<TFieldValues, TName>['validate'] = rest.required
    ? (v) => {
        const valid = z.string().min(1).safeParse(v);
        if (!valid.success) return 'Required';
        return;
      }
    : undefined;

  return (
    <Controller
      rules={{ validate }}
      name={name}
      control={control}
      shouldUnregister={shouldUnregister}
      render={(props) => DateInputRender(props, rest)}
    />
  );
}

export const TimeInputRender: InputRenderer<DateTimeInputProps<GQLTime>> = (renderProps, props) => {
  const {
    field: { onChange, onBlur, value, name },
    fieldState: { error },
  } = renderProps;
  const formContext = useFormContext();
  const label =
    typeof props.label === 'string' && props.label ? props.label : props.accessibilityLabel;
  if (formContext && label) formContext.labels[name] = label;
  return (
    <DateTimeInput
      testID={`FormInput_${name.replaceAll('.', '_')}`}
      mode="time"
      onChangeValue={(v) => {
        onChange(v);
        onBlur();
      }}
      value={value}
      error={error?.message}
      {...props}
    />
  );
};

export function TimeFormInput<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  control,
  name,
  shouldUnregister,
  ...rest
}: ControllerProps<TFieldValues, TName> & Parameters<typeof TimeInputRender>[1]) {
  const validate: RegisterOptions<TFieldValues, TName>['validate'] = rest.required
    ? (v) => {
        const valid = z.string().min(1).safeParse(v);
        if (!valid.success) return 'Required';
        return;
      }
    : undefined;
  return (
    <Controller
      rules={{ validate }}
      name={name}
      control={control}
      shouldUnregister={shouldUnregister}
      render={(props) => TimeInputRender(props, rest)}
    />
  );
}

export const SwitchInputRender: InputRenderer<ComponentProps<typeof SwitchInput>> = (
  renderProps,
  props,
) => {
  const {
    field: { onChange, onBlur, value, name },
  } = renderProps;
  return (
    <SwitchInput
      testID={`FormInput_${name.replaceAll('.', '_')}`}
      onChangeValue={(v) => {
        onChange(v);
        onBlur();
      }}
      value={value}
      showOnOff
      {...props}
    />
  );
};

export const RadioInputRender: InputRenderer<ComponentProps<typeof RadioInput>> = (
  renderProps,
  props,
) => {
  const {
    field: { onChange, onBlur, value, name },
    fieldState: { error },
  } = renderProps;
  const formContext = useFormContext();
  const label =
    typeof props.label === 'string' && props.label ? props.label : props.accessibilityLabel;
  if (formContext && label) formContext.labels[name] = label;
  return (
    <RadioInput
      testID={`FormInput_${name.replaceAll('.', '_')}`}
      error={error?.message}
      onChangeValue={(v) => {
        onChange(v);
        onBlur();
      }}
      value={value}
      {...props}
    />
  );
};

export function RadioFormInput<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  control,
  name,
  shouldUnregister,
  ...rest
}: UseControllerProps<TFieldValues, TName> & Parameters<typeof RadioInputRender>[1]) {
  return (
    <Controller
      name={name}
      control={control}
      shouldUnregister={shouldUnregister}
      render={(props) => RadioInputRender(props, rest)}
    />
  );
}

const ImageInputRender: InputRenderer<ComponentProps<typeof ImageInput>> = (renderProps, props) => {
  const {
    field: { onChange, onBlur, value, name },
    fieldState: { error },
  } = renderProps;
  const formContext = useFormContext();
  const label =
    typeof props.label === 'string' && props.label ? props.label : props.accessibilityLabel;
  if (formContext && label) formContext.labels[name] = label;
  return (
    <ImageInput
      testID={`FormInput_${name.replaceAll('.', '_')}`}
      error={error?.message}
      onChangeValue={(v) => {
        onChange(v);
        onBlur();
      }}
      value={value}
      {...props}
    />
  );
};

export function ImageFormInput<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  control,
  name,
  shouldUnregister,
  ...rest
}: UseControllerProps<TFieldValues, TName> & Parameters<typeof ImageInputRender>[1]) {
  const validate: RegisterOptions<TFieldValues, TName>['validate'] = rest.required
    ? (v) => {
        const valid = z.string().min(1).safeParse(v);
        if (!valid.success) return 'Required';
        return;
      }
    : undefined;
  return (
    <Controller
      rules={{ validate }}
      name={name}
      control={control}
      shouldUnregister={shouldUnregister}
      render={(props) => ImageInputRender(props, rest)}
    />
  );
}

const SegmentedControlInputRender: InputRenderer<
  ComponentProps<typeof SegmentedControlInput> & { mode?: 'single' | 'multiple' }
> = (renderProps, { mode, ...props }) => {
  const {
    field: { onChange, onBlur, value, name },
    fieldState: { error },
  } = renderProps;
  const formContext = useFormContext();
  const label =
    typeof props.label === 'string' && props.label ? props.label : props.accessibilityLabel;
  if (formContext && label) formContext.labels[name] = label;

  const finalValue =
    mode === 'single'
      ? // single
        value
        ? ([value] as unknown as string[])
        : []
      : // multiple
        value || [];

  return (
    <SegmentedControlInput
      testID={`FormInput_${name.replaceAll('.', '_')}`}
      error={error?.message}
      onChangeValue={(newValue) => {
        if (mode === 'single') {
          onChange(newValue.filter((v) => v !== finalValue[0])[0] as unknown as string[]);
        } else {
          onChange(newValue);
        }
        onBlur();
      }}
      value={finalValue}
      {...props}
    />
  );
};

export function SegmentedControlFormInput<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  control,
  name,
  shouldUnregister,
  ...rest
}: UseControllerProps<TFieldValues, TName> & Parameters<typeof SegmentedControlInputRender>[1]) {
  const validate: RegisterOptions<TFieldValues, TName>['validate'] = rest.required
    ? (v) => {
        const mode = rest.mode;
        const valid = mode === 'single' ? !!v : !!(Array.isArray(v) && v.length);
        if (!valid) return 'Required';
        return;
      }
    : undefined;
  return (
    <Controller
      rules={{ validate }}
      name={name}
      control={control}
      shouldUnregister={shouldUnregister}
      render={(props) => SegmentedControlInputRender(props, rest)}
    />
  );
}

export const NumberInputRender: InputRenderer<ComponentProps<typeof NumberInput>> = (
  renderProps,
  props,
) => {
  const {
    field: { onChange, onBlur, value, name },
    fieldState: { error },
  } = renderProps;
  const formContext = useFormContext();
  const label =
    typeof props.label === 'string' && props.label ? props.label : props.accessibilityLabel;
  if (formContext && label) formContext.labels[name] = label;

  return (
    <NumberInput
      testID={`FormInput_${name.replaceAll('.', '_')}`}
      onBlur={onBlur}
      onChangeValue={onChange}
      value={value}
      error={error?.message}
      {...props}
    />
  );
};

export function NumberFormInput<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  control,
  name,
  shouldUnregister,
  ...rest
}: ControllerProps<TFieldValues, TName> & Parameters<typeof NumberInputRender>[1]) {
  const validate: RegisterOptions<TFieldValues, TName>['validate'] = rest.required
    ? (v) => {
        const valid = z.number().safeParse(v);
        if (!valid.success) return 'Required';
        return;
      }
    : undefined;
  return (
    <Controller
      rules={{ validate }}
      name={name}
      control={control}
      shouldUnregister={shouldUnregister}
      render={(props) => NumberInputRender(props, rest)}
    />
  );
}

export function useSubFormContext<
  T extends z.Schema,
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>(_schema: T, _control: Control<TFieldValues>, name: string) {
  const form = useFormContext();

  type Return = Pick<UseFormReturn<z.output<T>>, 'register' | 'setValue' | 'watch'> & {
    setRootValue: (value: z.output<T>) => void;
    getName: (subpath: FieldPath<z.output<T>>) => TName;
    getError: (subpath: '' | FieldPath<z.output<T>>) => FieldError | undefined;
  };

  const _setValue = form.setValue;

  // setValue doesn't play nicely with objects with nested fields... instead we should manually
  // iterate and setValue on each key in the object
  // https://github.com/react-hook-form/react-hook-form/issues/10795
  const setRootValue = useCallback<Return['setRootValue']>(
    (value) => {
      for (let key of recordKeys(value)) {
        _setValue(`${name}.${key as string}`, value[key]);
      }
    },
    [_setValue, name],
  );

  const setValue = useCallback<Return['setValue']>(
    (subpath, value, options) =>
      _setValue(
        `${name}.${subpath}`,
        // eslint-disable-next-line
        value as any,
        options,
      ),
    [_setValue, name],
  );

  const _register = form.register;
  const register = useCallback<Return['register']>(
    (subpath, options) => _register(`${name}.${subpath}`, options),
    [_register, name],
  );

  const _watch = form.watch;
  const watch = useCallback<Return['watch']>(
    // @ts-expect-error we don't properly handle all overloads
    (subpath) => {
      return _watch(subpath ? `${name}.${subpath}` : name);
    },
    [_watch, name],
  );

  const getName = useCallback<Return['getName']>(
    (subpath) => `${name}.${subpath}` as TName,
    [name],
  );

  const errors = form.formState.errors;
  const getError = useCallback<Return['getError']>(
    (subpath) => {
      return get(errors, subpath ? (`${name}.${subpath}` as TName) : name) as
        | FieldError
        | undefined;
    },
    [errors, name],
  );

  return { setRootValue, setValue, register, watch, getName, getError };
}

export function FormContainer<
  TFieldValues extends FieldValues,
  TContext = any, // eslint-disable-line
  TTransformedValues extends FieldValues | undefined = undefined,
>(props: ComponentProps<typeof FormProvider<TFieldValues, TContext, TTransformedValues>>) {
  return (
    <FormProvider {...props}>
      <ErrorPresenter formErrors={flattenErrorMessages(props.formState.errors, props.labels)} />
      {props.children}
    </FormProvider>
  );
}

export { Controller, FieldValues, Control, FieldPath, useFieldArray };
