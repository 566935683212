import pickBy from 'lodash/pickBy';
import { useEffect, useMemo, useState } from 'react';

import { RadioInput } from '@oui/app-core/src/components/RadioInput';
import states from '@oui/lib/src/metadata/states.json';
import {
  AddressInput,
  AddressUse,
  CaringContactsMedium,
  ContactPointSystem,
  ContactPointUse,
  OuiUserRoleType,
  ProductVariant,
} from '@oui/lib/src/types/graphql.generated';
import { GQLDate } from '@oui/lib/src/types/scalars';

import { ButtonAccordion } from '@src/components/Accordion';
import { Button } from '@src/components/Button';
import { Checkbox } from '@src/components/Checkbox';
import DateTimeInput from '@src/components/DateTimeInput';
import { ErrorPresenter } from '@src/components/ErrorPresenter';
import {
  useAddAddressForPatientMutation,
  useAddContactPointForPatientMutation,
} from '@src/components/PatientSectionDetails.graphql.generated';
import { PhoneInput } from '@src/components/PhoneInput';
import PickerInput from '@src/components/PickerInput';
import { PlatformScreen } from '@src/components/PlatformScreen';
import { Heading, Text } from '@src/components/Text';
import TextInput, { EmailInput } from '@src/components/TextInput';
import { View } from '@src/components/View';
import { useCurrentUser, useOrganizations } from '@src/hooks/useCurrentUser';
import { useForm } from '@src/hooks/useForm';
import {
  GENDER_IDENTITY_V2,
  MILITARY_STATUS_V2,
  RACE_V2,
  SEXUAL_ORIENTATION_V2,
} from '@src/lib/demo';
import {
  DEMOGRAPHIC_LABELS,
  INTERVENTION_LABELS,
  listDemographicsForProductVariants,
  listPossibleInterventions,
  ProductDemographic,
  ProductIntervention,
} from '@src/lib/product';
import { useAdminOrganizationQuery } from '@src/screens/Organization.graphql.generated';
import {
  AddPatientMutationVariables,
  useAddPatientMutation,
} from '@src/screens/Patient.graphql.generated';
import { OrganizationPicker } from '@src/screens/PatientsV2';
import { Color } from '@src/styles';
import { CliniciansScreenProps } from '@src/types';
import { namedAvivaOperations } from '@src/types/namedOperations.generated';

const ALLOW_BLANK_PATIENT_EMAIL = false;

export function NewPatientV2(props: CliniciansScreenProps<'NewPatient'>) {
  const [createUser, { error: createUserError, loading: isLoading }] = useAddPatientMutation({
    refetchQueries: [namedAvivaOperations.Query.PatientsList],
  });
  const [addAddress] = useAddAddressForPatientMutation();
  const [addContactPoint] = useAddContactPointForPatientMutation();
  const { data: organizationsData } = useOrganizations();
  const { data: currentUser } = useCurrentUser();
  const organization = props.route.params?.organizationID
    ? organizationsData?.organizations?.find((org) => org.ID === props.route.params.organizationID)
    : organizationsData?.organizations?.[0];
  const [caringContactsEnabled, setCaringContactsEnabled] = useState(
    !!organization?.caringContactsEnabled,
  );
  const [productVariantConfirmation, setProductVariantConfirmation] = useState<ProductVariant>();
  const { data: fullOrganization } = useAdminOrganizationQuery({
    variables: { organizationID: organization?.ID!, inheritMembers: true },
    skip: !organization?.ID,
  });

  const practitionerItems = useMemo(() => {
    return (
      fullOrganization?.organizationByID?.practitioners
        ?.map((p) => ({
          value: p.ID,
          label: `${p.person.givenName} ${p.person.familyName}`,
        }))
        .sort((a, b) => (a.label < b.label ? -1 : 1)) ?? []
    );
  }, [fullOrganization]);

  const productVariants = organization?.productVariants ?? [];
  const possibleInterventions = listPossibleInterventions(productVariants || []);
  const possibleDemographics = listDemographicsForProductVariants(productVariants);

  const currentUserPractitionerID =
    currentUser?.currentUser?.roles.find(
      (role) =>
        (role.role === OuiUserRoleType.PRACTITIONER &&
          fullOrganization?.organizationByID?.ID === role.organization!.ID) ||
        fullOrganization?.organizationByID?.parent?.ID === role.organization!.ID,
    )?.user.ID ?? undefined;

  const {
    validate,
    bind,
    data: formData,
    humanErrors,
  } = useForm<
    AddPatientMutationVariables & {
      productIntervention: ProductIntervention;
      productDemographic: ProductDemographic;
      phone: string;
      address: AddressInput;
    }
  >({
    organizationID: organization?.ID!,
    patient: {
      birthDate: '' as GQLDate,
      person: {
        email: '',
        givenName: '',
        familyName: '',
      },
      productVariant: undefined,
      productVersion: organization?.productVersion,
      caringContactsMedium: caringContactsEnabled
        ? CaringContactsMedium.MAIL
        : CaringContactsMedium.NONE,
    },
    productIntervention: possibleInterventions.length === 1 ? possibleInterventions[0] : undefined,
    productDemographic: possibleDemographics.length === 1 ? possibleDemographics[0] : undefined,
    primaryPractitionerID: currentUserPractitionerID,
    phone: '',
    address: {
      line: [],
      city: '',
      state: '',
      postalCode: '',
      rank: 1,
      use: AddressUse.HOME,
    },
  });

  useEffect(() => {
    setCaringContactsEnabled(!!organization?.caringContactsEnabled);
  }, [organization]);

  // AVIVA_STATIC users cannot receive caring contacts
  useEffect(() => {
    if (formData.patient.productVariant === ProductVariant.AVIVA_STATIC) {
      setCaringContactsEnabled(formData.patient.productVariant !== ProductVariant.AVIVA_STATIC);
    }
  }, [formData.patient.productVariant]);

  const submit = () => {
    if (validate()) {
      return createUser({
        variables: {
          organizationID: formData.organizationID,
          primaryPractitionerID: formData.primaryPractitionerID,
          patient: {
            ...(pickBy(formData.patient) as typeof formData.patient),
            productVariant:
              formData.patient.productVariant ??
              (`${formData.productIntervention}_${formData.productDemographic}` as ProductVariant),
            caringContactsMedium: caringContactsEnabled
              ? formData.patient.caringContactsMedium
              : CaringContactsMedium.NONE,
          },
        },
      }).then((result) => {
        if (result?.data?.addPatient) {
          const patientID = result.data.addPatient.ID;

          if (formData.address.line?.[0]) {
            addAddress({ variables: { patientID, address: formData.address } });
          }
          if (formData.phone) {
            addContactPoint({
              variables: {
                patientID,
                contactPoint: {
                  rank: 1,
                  system: ContactPointSystem.PHONE,
                  use: ContactPointUse.MOBILE,
                  value: formData.phone,
                },
              },
            });
          }

          props.navigation.replace('Patient', {
            patientID,
            anchor: 'details',
          });
        }
      });
    }

    return;
  };

  const { value: caringContactsMedium, onChangeValue: onChangeCaringContactsMedium } = bind(
    ['patient', 'caringContactsMedium'],
    { label: 'Caring contacts medium' },
  );
  const addressValidator =
    caringContactsMedium === CaringContactsMedium.MAIL ? { type: 'present' as const } : undefined;

  return (
    <PlatformScreen
      testID="NewPatient"
      innerTestID="NewPatient_scroll"
      crumbs={[
        {
          label: 'Patients',
          to: 'patients',
          params: { organizationID: organization?.ID },
        },
        { label: 'Add new patient', to: '' },
      ]}
    >
      <Heading text="Add new patient" level={2} />
      <View spacing={30}>
        <View
          style={{
            backgroundColor: 'white',
            paddingHorizontal: 25,
            paddingVertical: 20,
            borderRadius: 10,
            maxWidth: 600,
            flex: 1,
            marginTop: 20,
          }}
          spacing={10}
        >
          {createUserError || Object.keys(humanErrors).length ? (
            <ErrorPresenter error={createUserError} formErrors={humanErrors} />
          ) : null}
          {(organizationsData?.organizations?.length ?? 0) > 1 ? (
            <OrganizationPicker label="Organization" />
          ) : null}
          <Heading text="Patient" style={{ marginTop: 30 }} level={3} />
          <View row spacing={30} childFlex={1}>
            <TextInput
              {...bind(['patient', 'person', 'givenName'], {
                validator: { type: 'present' },
                label: 'First name*',
              })}
              placeholder="Christina"
            />
            <TextInput
              {...bind(['patient', 'person', 'familyName'], { label: 'Last name' })}
              placeholder="Smith"
            />
          </View>
          <View spacing={10}>
            <Heading text="Product" style={{ marginTop: 30 }} level={3} />
            {organization?.isTrialOrganization ? (
              <View row spacing={30} childFlex={1}>
                <PickerInput
                  {...bind(['patient', 'productVariant'], {
                    label: 'Intervention*',
                    validator: [
                      { type: 'present' },
                      () => {
                        if (productVariantConfirmation !== formData.patient.productVariant) {
                          return 'Must be confirmed';
                        }
                        return;
                      },
                    ],
                  })}
                  placeholder="Select intervention"
                  items={[
                    { label: 'Abc', value: ProductVariant.AVIVA_ADULT },
                    { label: 'Steps', value: ProductVariant.AVIVA_STATIC },
                  ]}
                  testID="NewPatient_productVariantInput"
                />
                <PickerInput
                  value={productVariantConfirmation}
                  onChangeValue={setProductVariantConfirmation}
                  items={[
                    { label: 'Abc', value: ProductVariant.AVIVA_ADULT },
                    { label: 'Steps', value: ProductVariant.AVIVA_STATIC },
                  ]}
                  placeholder="Select intervention to confirm"
                  label="Confirm intervention*"
                  testID="NewPatient_productVariantConfirmationInput"
                />
              </View>
            ) : (
              <View row spacing={30} childFlex={1}>
                {possibleInterventions.length > 1 ? (
                  <PickerInput
                    {...bind('productIntervention', {
                      label: 'Intervention*',
                      validator: { type: 'present' },
                    })}
                    placeholder="Select intervention"
                    items={possibleInterventions.map((i) => ({
                      value: i,
                      label: INTERVENTION_LABELS[i]!,
                    }))}
                  />
                ) : (
                  <View spacing={5}>
                    <Text weight="semibold" text="Intervention" />
                    <Text text={INTERVENTION_LABELS[possibleInterventions[0]]!} />
                  </View>
                )}
                {possibleDemographics.length > 1 ? (
                  <PickerInput
                    {...bind(['productDemographic'], {
                      label: 'Demographic*',
                      validator: { type: 'present' },
                    })}
                    disabled={possibleInterventions.length > 1 && !formData.productIntervention}
                    placeholder="Select demographic"
                    items={possibleDemographics.map((i) => ({
                      value: i,
                      label: DEMOGRAPHIC_LABELS[i],
                    }))}
                  />
                ) : (
                  <View spacing={5}>
                    <Text weight="semibold" text="Demographic" />
                    <Text text={DEMOGRAPHIC_LABELS[possibleDemographics[0]]!} />
                  </View>
                )}
              </View>
            )}
          </View>
          <Heading text="Contact info" style={{ marginTop: 30 }} level={3} />
          <View row spacing={30} childFlex={1}>
            <EmailInput
              {...bind(['patient', 'person', 'email'], {
                validator: {
                  type: 'email',
                  allowBlank: ALLOW_BLANK_PATIENT_EMAIL,
                },
                label: ALLOW_BLANK_PATIENT_EMAIL ? 'Email address' : 'Email address*',
              })}
              placeholder="email@domain.com"
            />
            <PhoneInput
              {...bind('phone', {
                label:
                  caringContactsMedium === CaringContactsMedium.SMS
                    ? 'Phone number*'
                    : 'Phone number',
                validator: {
                  type: 'phone',
                  allowBlank: caringContactsMedium !== CaringContactsMedium.SMS,
                },
              })}
            />
          </View>
          <View>
            <TextInput
              {...bind(['address', 'line', 0], {
                label: addressValidator ? 'Mailing address*' : 'Mailing address',
                validator: addressValidator,
              })}
              placeholder="Street address"
            />
            <TextInput
              {...bind(['address', 'line', 1], { accessibilityLabel: 'Address Line 2' })}
              placeholder="Address Line 2"
            />
            <View row spacing={12} style={{ flexWrap: 'wrap' }}>
              <TextInput
                {...bind(['address', 'city'], {
                  accessibilityLabel: 'City',
                  validator: addressValidator,
                })}
                placeholder="City"
                style={{ width: 200 }}
              />
              <PickerInput
                {...bind(['address', 'state'], { accessibilityLabel: 'State' })}
                items={states.map((s) => ({ label: s.name, value: s.abbreviation }))}
                style={{ width: 150 }}
              />
              <TextInput
                {...bind(['address', 'postalCode'], {
                  accessibilityLabel: 'Zip',
                  validator: addressValidator,
                })}
                placeholder="ZIP code"
                style={{ width: 100 }}
              />
            </View>
          </View>
          {caringContactsEnabled ? (
            <>
              <Checkbox
                testID="NewPatient_caringContactsCheckbox"
                label="Patient agrees to receive caring contacts via mail as part of treatment."
                labelWeight="normal"
                horizontal
                value={caringContactsMedium === CaringContactsMedium.MAIL}
                onChangeValue={(v) => {
                  if (v) {
                    onChangeCaringContactsMedium(CaringContactsMedium.MAIL);
                  } else {
                    onChangeCaringContactsMedium(CaringContactsMedium.EMAIL);
                  }
                }}
              />
              {caringContactsMedium !== CaringContactsMedium.MAIL ? (
                <RadioInput
                  style={{ marginLeft: -16 }}
                  label="Other method the patient would prefer to receive caring contacts"
                  labelWeight="normal"
                  horizontal
                  items={[
                    { label: 'Email', value: CaringContactsMedium.EMAIL },
                    { label: 'SMS', value: CaringContactsMedium.SMS },
                    { label: 'None', value: CaringContactsMedium.NONE },
                  ]}
                  value={caringContactsMedium}
                  onChangeValue={onChangeCaringContactsMedium}
                  testID="NewPatient_caringContactsRadioInput"
                />
              ) : null}
            </>
          ) : null}
          {organization?.isTrialOrganization &&
          currentUserPractitionerID &&
          formData.primaryPractitionerID === currentUserPractitionerID ? null : (
            <View spacing={10}>
              <Heading text="Care team" style={{ marginTop: 30 }} level={3} />
              <PickerInput
                {...bind('primaryPractitionerID', {
                  label: 'Primary clinician*',
                  validator: { type: 'present' },
                })}
                items={practitionerItems}
                placeholder="Select primary clinician"
              />
            </View>
          )}
          <View
            style={{
              marginTop: 20,
              marginHorizontal: -25,
            }}
          >
            <ButtonAccordion
              color={Color.styleGuide.Gray4}
              text="Additional info"
              style={{
                alignSelf: 'flex-end',
                borderBottomEndRadius: 0,
                borderBottomStartRadius: 0,
                paddingHorizontal: 25,
                paddingVertical: 10,
                borderWidth: 0,
              }}
              expandedStyle={{
                backgroundColor: Color.grayBackground,
              }}
            >
              <View
                style={{
                  paddingVertical: 20,
                  paddingHorizontal: 40,
                  backgroundColor: Color.grayBackground,
                }}
                spacing={30}
              >
                <View row spacing={30} childFlex={1}>
                  <DateTimeInput
                    {...bind(['patient', 'birthDate'], { label: 'Date of birth' })}
                    placeholder="MM / DD / YYYY"
                    label="Date of birth"
                    mode="date"
                  />
                  <PickerInput
                    {...bind(['patient', 'race'], { label: 'Racial identity' })}
                    placeholder="Choose one"
                    items={RACE_V2}
                  />
                </View>
                <View row spacing={30} childFlex={1}>
                  <PickerInput
                    {...bind(['patient', 'genderIdentity'], { label: 'Gender identity' })}
                    placeholder="Choose one"
                    items={GENDER_IDENTITY_V2}
                  />
                  <PickerInput
                    {...bind(['patient', 'sexualOrientation'], { label: 'Sexual orientation' })}
                    placeholder="Choose one"
                    items={SEXUAL_ORIENTATION_V2}
                  />
                </View>
                <View row spacing={30} childFlex={1}>
                  <PickerInput
                    {...bind(['patient', 'militaryStatus'], { label: 'Military status' })}
                    placeholder="Choose one"
                    items={MILITARY_STATUS_V2}
                  />
                </View>
              </View>
            </ButtonAccordion>
          </View>
          <Button
            testID="NewPatient_submit"
            alignSelf="center"
            style={{ marginTop: 12 }}
            disabled={isLoading}
            text="Save"
            pressOnce
            onPress={submit}
          />
        </View>
      </View>
    </PlatformScreen>
  );
}
