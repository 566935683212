import capitalize from 'lodash/capitalize';
import { ComponentProps, useState } from 'react';

import { ResultOf } from '@oui/lib/src/graphql/tada';
import states from '@oui/lib/src/metadata/states.json';
import {
  AddressUse,
  ContactPointSystem,
  ContactPointUse,
  PatientMilitaryStatus,
  Person,
} from '@oui/lib/src/types/graphql.generated';

import { Button } from '@src/components/Button';
import DateTimeInput from '@src/components/DateTimeInput';
import { ErrorPresenter } from '@src/components/ErrorPresenter';
import { Icon } from '@src/components/Icon';
import {
  useAddAddressForPatientMutation,
  useAddContactPointForPatientMutation,
  useUpdatePatientMutation,
} from '@src/components/PatientSectionDetails.graphql.generated';
import { PhoneInput } from '@src/components/PhoneInput';
import PickerInput from '@src/components/PickerInput';
import { OldHeading, OldSubheading, Text } from '@src/components/Text';
import { EmailInput, TextInput } from '@src/components/TextInput';
import { UserAddress } from '@src/components/UserAddress';
import { View } from '@src/components/View';
import { useForm } from '@src/hooks/useForm';
import { PatientQuery } from '@src/hooks/usePatient';
import {
  GENDER_IDENTITY_V2,
  MILITARY_STATUS_V2,
  RACE_V2,
  SEXUAL_ORIENTATION_V2,
} from '@src/lib/demo';
import { Color } from '@src/styles';
import { Address } from '@src/types';

function humanizeEnumKey(key: string) {
  return key.split('_').map(capitalize).join(' ');
}

function Label(props: Omit<ComponentProps<typeof Text>, 'size' | 'color'>) {
  return <Text size={13} color={Color.styleGuide.Gray4} {...props} />;
}

export function PatientSectionDetails({
  patient,
}: {
  patient: NonNullable<ResultOf<typeof PatientQuery>['patientByPatientID']>['patient'];
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [updatePatient] = useUpdatePatientMutation();
  const [addAddress] = useAddAddressForPatientMutation();
  const [addContactPoint] = useAddContactPointForPatientMutation();

  const phoneValue = patient.person.contactPoint?.find(
    (point) => point.system === ContactPointSystem.PHONE,
  )?.value;
  const emailValue =
    patient.person.email ??
    patient.person.contactPoint?.find((point) => point.system === ContactPointSystem.EMAIL)?.value;

  const { data, bind, validate, humanErrors } = useForm<
    Omit<Person, 'address'> & {
      address?: Address;
    }
  >({
    birthDate: patient.person.birthDate === '0001-01-01' ? undefined : patient.person.birthDate,
    genderIdentity: patient.person.genderIdentity,
    familyName: patient.person.familyName ?? '',
    givenName: patient.person.givenName ?? '',
    email: emailValue,
    militaryStatus: patient.person.militaryStatus,
    race: patient.person.race,
    sexualOrientation: patient.person.sexualOrientation,
    phone: phoneValue,
    address: patient.person.address?.[0],
  });

  async function save() {
    if (!validate()) return;
    const promises: Promise<unknown>[] = [];

    promises.push(
      updatePatient({
        variables: {
          patientID: patient.ID,
          data: {
            person: {
              birthDate: data.birthDate,
              genderIdentity: data.genderIdentity,
              familyName: data.familyName,
              givenName: data.givenName,
              militaryStatus: data.militaryStatus || PatientMilitaryStatus.NON_DISCLOSE,
              race: data.race,
              sexualOrientation: data.sexualOrientation,
            },
          },
        },
      }),
    );

    if (data.phone && data.phone !== phoneValue) {
      promises.push(
        addContactPoint({
          variables: {
            patientID: patient.ID,
            contactPoint: {
              rank: 1,
              system: ContactPointSystem.PHONE,
              use: ContactPointUse.MOBILE,
              value: data.phone,
            },
          },
        }),
      );
    }

    if (data.address) {
      promises.push(
        addAddress({
          variables: {
            patientID: patient.ID,
            address: {
              rank: 1,
              use: AddressUse.HOME,
              ...data.address,
            },
          },
        }),
      );
    }

    await Promise.all(promises);
    setIsEditing(false);
  }

  return (
    <View spacing={16} testID="Patient_sectionDetails">
      <View row style={{ justifyContent: 'space-between' }}>
        <OldHeading text="Patient Info" />
        {isEditing ? (
          <Button text="Save" onPress={save} testID="Patient_saveDetailsButton" />
        ) : (
          <Icon
            name="edit"
            onPress={() => setIsEditing(true)}
            accessibilityLabel="Edit"
            testID="Patient_editDetailsButton"
          />
        )}
      </View>
      {isEditing ? (
        <View style={{ backgroundColor: 'white', padding: 32, borderRadius: 16 }} spacing={10}>
          <ErrorPresenter formErrors={humanErrors} />
          <OldSubheading text="Patient details" />
          <View row childFlex={1} spacing={20}>
            <View>
              <TextInput {...bind(['givenName'], { label: 'First name' })} />
            </View>
            <View>
              <TextInput {...bind(['familyName'], { label: 'Last name' })} />
            </View>
          </View>

          <OldSubheading text="Contact info" />
          <View row childFlex={1} style={{ alignItems: 'flex-start' }} spacing={20}>
            <View>
              <EmailInput {...bind(['email'], { label: 'Email', validator: { type: 'email' } })} />
            </View>
            <View>
              <PhoneInput {...bind('phone', { label: 'Phone number' })} />
            </View>
          </View>

          <View>
            <Label text="Address" />
            <View spacing={15}>
              <View row childFlex={1} spacing={15}>
                <TextInput
                  {...bind(['address', 'line', 0], { accessibilityLabel: 'Street address' })}
                  placeholder="Street address"
                />
                <TextInput
                  {...bind(['address', 'line', 1], { accessibilityLabel: 'Address Line 2' })}
                  placeholder="Address Line 2"
                />
              </View>
              <View row childFlex={1} spacing={15}>
                <TextInput
                  {...bind(['address', 'city'], { accessibilityLabel: 'City' })}
                  placeholder="City"
                  style={{ width: 200 }}
                />
                <View row spacing={16} childFlex={1}>
                  <PickerInput
                    {...bind(['address', 'state'], { accessibilityLabel: 'State' })}
                    items={states.map((s) => ({ label: s.name, value: s.abbreviation }))}
                    style={{ width: 150 }}
                  />
                  <TextInput
                    {...bind(['address', 'postalCode'], { accessibilityLabel: 'Zip code' })}
                    placeholder="Zip"
                    style={{ width: 100 }}
                  />
                </View>
              </View>
            </View>
          </View>

          <OldSubheading text="Personal info" />
          <View row childFlex={1} spacing={20}>
            <View>
              <DateTimeInput
                label="Date of birth"
                {...bind('birthDate', { label: 'Date of birth' })}
                placeholder="MM / DD / YYYY"
                mode="date"
              />
            </View>
            <View>
              <PickerInput
                {...bind(['race'], { label: 'Racial identity' })}
                placeholder="Choose one"
                items={RACE_V2}
              />
            </View>
          </View>
          <View row childFlex={1} spacing={20}>
            <View>
              <PickerInput
                {...bind(['genderIdentity'], { label: 'Gender identity' })}
                placeholder="Choose one"
                label="Gender identity"
                items={GENDER_IDENTITY_V2}
              />
            </View>
            <View>
              <PickerInput
                {...bind(['sexualOrientation'], { label: 'Sexual orientation' })}
                placeholder="Choose one"
                label="Sexual orientation"
                items={SEXUAL_ORIENTATION_V2}
              />
            </View>
          </View>
          <View row childFlex={1} spacing={20}>
            <View>
              <PickerInput
                {...bind(['militaryStatus'], { label: 'Military status' })}
                placeholder="Choose one"
                items={MILITARY_STATUS_V2}
              />
            </View>
          </View>
        </View>
      ) : (
        <View style={{ backgroundColor: 'white', padding: 32, borderRadius: 16 }} spacing={10}>
          <OldSubheading text="Patient details" />
          <View row childFlex={1}>
            <View>
              <Label text="First name" />
              <Text text={patient.person.givenName!} />
            </View>
            <View>
              <Label text="Last name" />
              <Text text={patient.person.familyName!} />
            </View>
          </View>

          <OldSubheading text="Contact info" />
          <View row childFlex={1} style={{ alignItems: 'flex-start' }}>
            <View>
              <Label text="Email" />
              <Text text={emailValue || 'Unknown'} />
            </View>
            <View>
              <Label text="Phone number" />
              <Text text={phoneValue || 'Unknown'} />
            </View>
          </View>

          <View>
            <Label text="Address" />
            {patient.person.address?.[0] ? (
              <UserAddress {...patient.person.address?.[0]} />
            ) : (
              <Text text={'Unknown'} />
            )}
          </View>

          <OldSubheading text="Personal info" />
          <View row childFlex={1}>
            <View>
              <Label text="Date of birth" />
              <Text
                text={
                  patient.person.birthDate && patient.person.birthDate !== '0001-01-01'
                    ? patient.person.birthDate
                    : 'Unknown'
                }
              />
            </View>
            <View>
              <Label text="Racial Identity" />
              <Text text={humanizeEnumKey(patient.person.race ?? 'Unknown')} />
            </View>
          </View>
          <View row childFlex={1}>
            <View>
              <Label text="Gender identity" />
              <Text text={humanizeEnumKey(patient.person.genderIdentity ?? 'Unknown')} />
            </View>
            <View>
              <Label text="Sexual orientation" />
              <Text text={humanizeEnumKey(patient.person.sexualOrientation ?? 'Unknown')} />
            </View>
          </View>
          <View row childFlex={1}>
            <View>
              <Label text="Veteran?" />
              <Text text={humanizeEnumKey(patient.person.militaryStatus ?? 'Unknown')} />
            </View>
          </View>
        </View>
      )}
    </View>
  );
}
