import { ComponentProps, ReactNode } from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import { Text } from '../components/Text';
import { TextInput } from '../components/TextInput';
import { View } from '../components/View';
import { useTheme } from '../styles';

export function MaxLengthTextInput({
  containerStyle,
  ...props
}: ComponentProps<typeof TextInput> & {
  maxLength: number;
  containerStyle?: StyleProp<ViewStyle>;
  bottomContent?: ReactNode;
}) {
  const { Color } = useTheme();
  return (
    <View style={containerStyle}>
      <TextInput {...props} />
      <View row style={{ justifyContent: 'space-between', marginHorizontal: 20 }}>
        <Text
          size={13}
          text={`${props.maxLength - (props.value?.length ?? 0)} characters left`}
          style={{ color: Color.styleGuide.Gray4 }}
        />
        {props.bottomContent}
      </View>
    </View>
  );
}
