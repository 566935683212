import { useIsFocused, useScrollToTop } from '@react-navigation/native';
import noop from 'lodash/noop';
import { Component, createContext, ReactNode, useContext, useEffect, useRef } from 'react';
import { Platform, StyleSheet } from 'react-native';
import {
  KeyboardAwareScrollView,
  KeyboardAwareScrollViewProps,
} from 'react-native-keyboard-aware-scroll-view';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useDebounce } from 'use-debounce';

import { View } from '../components/View';

// import { Text } from '../components/Text';
// import { Color } from '../styles';
type ElementLayout = {
  x: number;
  y: number;
  width: number;
  height: number;
};
type ContentOffset = {
  x: number;
  y: number;
};
type ScrollPosition = {
  x: number;
  y: number;
  animated: boolean;
};

export type ScrollIntoViewOptions = {
  getScrollPosition?: (
    parentLayout: ElementLayout,
    childLayout: ElementLayout,
    contentOffset: ContentOffset,
  ) => ScrollPosition;
};

type ContextValueType = {
  scrollToElement: (el: Component | null, options?: ScrollIntoViewOptions) => number;
};
export const ScrollViewContext = createContext<ContextValueType>({
  scrollToElement: noop as any, // eslint-disable-line
});

export function ScrollHere(props: { animated?: boolean; offsetY?: number }) {
  const ref = useRef(null);
  const { scrollToElement } = useContext(ScrollViewContext);
  const insets = useSafeAreaInsets();

  useEffect(() => {
    if (ref.current) {
      const timeout = scrollToElement(ref.current, {
        getScrollPosition: (parentLayout, childLayout, contentOffset) => {
          return {
            x: 0,
            y: Math.max(
              0,
              childLayout.y - parentLayout.y - insets.top - (props.offsetY ?? contentOffset.y ?? 0),
            ),
            animated: props.animated ?? true,
          };
        },
      });
      return () => clearTimeout(timeout);
    }
    return;
  }, [scrollToElement, props.animated, props.offsetY, insets.top]);

  // NB android requires size + backgroundColor to measure the layout properly
  return (
    <View
      ref={ref}
      style={{ width: '100%', height: 1, backgroundColor: 'transparent', marginBottom: -1 }}
    />
  );
}

const useIsFocusedSafe =
  Platform.OS === 'web'
    ? () => {
        return true;
      }
    : useIsFocused;
const useScrollToTopSafe = Platform.OS === 'web' ? () => {} : useScrollToTop;

export function ScrollView({
  topOverflowColor,
  bottomOverflowColor,
  ...props
}: KeyboardAwareScrollViewProps & {
  children?: ReactNode;
  topOverflowColor?: string;
  bottomOverflowColor?: string;
}) {
  const ref = useRef<KeyboardAwareScrollView>(null);
  const timeout = useRef(0);
  // KeyboardAwareScrollView by default listens to global Keyboard events for the logic
  // toggled by enableResetScrollToCoords. We dont want ScrollView to respond to keyboard events
  // unless they occur on this screen (i.e. this screen is currently focused)
  // We also need to debounce so that we dont start listening to events until the screen transition
  // is complete
  const [isFocused] = useDebounce(useIsFocusedSafe(), 100);
  const insets = useSafeAreaInsets();

  const valueRef = useRef<ContextValueType>({
    scrollToElement: (el, options) => {
      clearTimeout(timeout.current);
      timeout.current = setTimeout(() => {
        if (ref.current && el) {
          // eslint-disable-next-line
          (ref.current as any).scrollIntoView(el, options);
        }
      }, 200) as unknown as number;
      return timeout.current;
    },
  });

  // eslint-disable-next-line
  useScrollToTopSafe(ref as any);

  // ScrollViews typically extend to the bottom of the screen where navigation
  // UI is. This logic ensures we always have sufficient padding to avoid being covered by that UI
  const flatStyle = StyleSheet.flatten(props.contentContainerStyle);
  const providedPaddingBottom =
    flatStyle.paddingBottom ?? flatStyle.paddingVertical ?? flatStyle.padding ?? 0;
  const paddingBottom =
    typeof providedPaddingBottom === 'number'
      ? insets.bottom + providedPaddingBottom
      : providedPaddingBottom;

  return (
    <ScrollViewContext.Provider value={valueRef.current}>
      {topOverflowColor || bottomOverflowColor ? (
        <View style={StyleSheet.absoluteFillObject}>
          <View style={{ flex: 1, backgroundColor: topOverflowColor || 'transparent' }} />
          <View style={{ flex: 1, backgroundColor: bottomOverflowColor || 'transparent' }} />
        </View>
      ) : null}
      <KeyboardAwareScrollView
        ref={ref}
        enableResetScrollToCoords={isFocused}
        // hide scroll indicators in e2e tests for image snapshot stability
        showsHorizontalScrollIndicator={!global.e2e}
        showsVerticalScrollIndicator={!global.e2e}
        scrollIndicatorInsets={props.horizontal !== false ? { right: Number.MIN_VALUE } : undefined}
        // give extra padding / cushion so inputs aren't crammed against the keyboard
        extraHeight={200}
        {...props}
        // need to flatten styles so KeyboardAwareScrollView can discover our paddingBottom value
        // https://github.com/APSL/react-native-keyboard-aware-scroll-view/blob/9eee405f7b3e261faf86a0fc8e495288d91c853e/lib/KeyboardAwareHOC.js#L529-L533
        contentContainerStyle={StyleSheet.flatten([props.contentContainerStyle, { paddingBottom }])}
        keyboardShouldPersistTaps="handled"
        enableOnAndroid
      />
    </ScrollViewContext.Provider>
  );
}
