import noop from 'lodash/noop';
import { useState } from 'react';
import { TouchableOpacity } from 'react-native';

import { CheckboxListInput } from '@oui/app-core/src/components/CheckboxListInput';
import { RadioInput } from '@oui/app-core/src/components/RadioInput';
import { OnboardingVariant, ProductVariant } from '@oui/lib/src/types/graphql.generated';

import { ControlledAccordion } from '@src/components/Accordion';
import Checkbox from '@src/components/Checkbox';
import { Text } from '@src/components/Text';
import { View } from '@src/components/View';
import { useSubsequentEffect } from '@src/hooks/useSubsequentEffect';
import { getPickerItemsFromEnum } from '@src/lib/getPickerItemsFromEnum';
import {
  DEMOGRAPHIC_LABELS,
  INTERVENTION_LABELS,
  listDemographicsForProductVariants,
  listPossibleInterventions,
  ProductIntervention,
} from '@src/lib/product';
import { useTheme } from '@src/styles';

export function ProductVariantsInput({
  value,
  onChangeValue,
  caringContactsEnabled,
  onChangeCaringContactsEnabled,
  onboardingVariant,
  onChangeOnboardingVariant,
}: {
  value: ReadonlyArray<ProductVariant>;
  onChangeValue: (newValue: ProductVariant[]) => void;
  caringContactsEnabled: boolean;
  onChangeCaringContactsEnabled: (newValue: boolean) => void;
  onboardingVariant: OnboardingVariant;
  onChangeOnboardingVariant: (newValue: OnboardingVariant) => void;
}) {
  const { Color } = useTheme();
  const [interventionStates, setInterventionStates] = useState<
    Record<ProductIntervention, boolean>
  >(() => {
    const interventions = listPossibleInterventions(value);
    return { AVIVA: interventions.includes('AVIVA'), STATIC: interventions.includes('STATIC') };
  });
  const [interventionAccordionStates, setInterventionAccordionStates] =
    useState<Record<ProductIntervention, boolean>>(interventionStates);

  useSubsequentEffect(() => {
    if (interventionStates.AVIVA) {
      setInterventionAccordionStates((s) => ({ ...s, AVIVA: true }));
      const hasSham = value.includes(ProductVariant.AVIVA_STATIC);
      onChangeValue(
        hasSham
          ? [ProductVariant.AVIVA_ADULT, ProductVariant.AVIVA_STATIC]
          : [ProductVariant.AVIVA_ADULT],
      );
    } else {
      setInterventionAccordionStates((s) => ({ ...s, AVIVA: false }));
      const hasSham = value.includes(ProductVariant.AVIVA_STATIC);
      onChangeValue(hasSham ? [ProductVariant.AVIVA_STATIC] : []);
    }
    // eslint-disable-next-line
  }, [interventionStates.AVIVA]);

  useSubsequentEffect(() => {
    if (interventionStates.STATIC) {
      setInterventionAccordionStates((s) => ({ ...s, STATIC: true }));
      onChangeValue([...value, ProductVariant.AVIVA_STATIC]);
    } else {
      setInterventionAccordionStates((s) => ({ ...s, STATIC: false }));
      onChangeValue(value.filter((v) => v !== ProductVariant.AVIVA_STATIC));
    }
    // eslint-disable-next-line
  }, [interventionStates.STATIC]);

  return (
    <View spacing={12}>
      <ControlledAccordion
        expanded={interventionAccordionStates.AVIVA}
        onSetExpanded={(newValue) => {
          setInterventionAccordionStates((s) => ({ ...s, AVIVA: newValue }));
        }}
        heading={
          // Wrap in TouchableOpacity so accordion doesn't collapse when clicking inside
          <TouchableOpacity activeOpacity={1} onPress={noop}>
            <Checkbox
              horizontal
              value={interventionStates.AVIVA}
              onChangeValue={(newValue) => {
                setInterventionStates((s) => ({ ...s, AVIVA: newValue }));
              }}
              label={INTERVENTION_LABELS.AVIVA}
            />
          </TouchableOpacity>
        }
        content={
          // Wrap in TouchableOpacity so accordion doesn't collapse when clicking inside
          <TouchableOpacity activeOpacity={1} onPress={noop}>
            <View
              spacing={20}
              style={{
                borderTopWidth: 1,
                paddingTop: 14,
                borderTopColor: Color.styleGuide.Gray6,
              }}
            >
              <View row childFlex={1}>
                <View>
                  <CheckboxListInput
                    disabled={!interventionStates.AVIVA}
                    labelSize={16}
                    items={{
                      ADULT: DEMOGRAPHIC_LABELS.ADULT,
                      ADOLESCENT: DEMOGRAPHIC_LABELS.ADOLESCENT,
                      INPATIENT: DEMOGRAPHIC_LABELS.INPATIENT,
                      MILITARY: DEMOGRAPHIC_LABELS.MILITARY,
                    }}
                    value={listDemographicsForProductVariants(value)}
                    onChangeValue={(newValue) => {
                      onChangeValue(newValue.map((v) => `AVIVA_${v}` as ProductVariant));
                    }}
                    label="Demographic*"
                    optionLabelWeight="normal"
                    hideHint
                  />
                </View>
                <View>
                  <RadioInput
                    disabled={!interventionStates.AVIVA}
                    spacing={8}
                    items={getPickerItemsFromEnum(OnboardingVariant)}
                    value={onboardingVariant}
                    onChangeValue={onChangeOnboardingVariant}
                    label="Onboarding type*"
                    optionLabelWeight="normal"
                  />
                </View>
              </View>
              <View>
                <Text text="Caring contacts" weight="semibold" style={{ marginBottom: 8 }} />
                <Checkbox
                  disabled={!interventionStates.AVIVA}
                  value={caringContactsEnabled}
                  onChangeValue={onChangeCaringContactsEnabled}
                  label="Enable allowing Caring Contacts notes for US-based patients"
                  horizontal
                  labelWeight="normal"
                />
              </View>
            </View>
          </TouchableOpacity>
        }
      />
      <ControlledAccordion
        expanded={interventionAccordionStates.STATIC}
        onSetExpanded={(newValue) => {
          setInterventionAccordionStates((s) => ({ ...s, STATIC: newValue }));
        }}
        heading={
          // Wrap in TouchableOpacity so accordion doesn't collapse when clicking inside
          <TouchableOpacity activeOpacity={1} onPress={noop}>
            <Checkbox
              horizontal
              value={interventionStates.STATIC}
              onChangeValue={(newValue) => {
                setInterventionStates((s) => ({ ...s, STATIC: newValue }));
              }}
              label={INTERVENTION_LABELS.STATIC}
            />
          </TouchableOpacity>
        }
        content={
          // Wrap in TouchableOpacity so accordion doesn't collapse when clicking inside
          <TouchableOpacity activeOpacity={1} onPress={noop}>
            <View
              spacing={20}
              style={{
                borderTopWidth: 1,
                paddingTop: 14,
                borderTopColor: Color.styleGuide.Gray6,
              }}
            >
              <View row childFlex={1}>
                <View>
                  <Text weight="semibold" text="Demographic" />
                  <Text text="Adult" />
                </View>
                <View>
                  <Text weight="semibold" text="Onboarding type" />
                  <Text text="Independent" />
                </View>
              </View>
            </View>
          </TouchableOpacity>
        }
      />
    </View>
  );
}
