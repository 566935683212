// This file is automatically generated. See gqlcodegen.yml for details
// prettier-ignore
// @ts-ignore
import * as CustomScalars from "@oui/lib/src/types/scalars";
import * as Types from '@oui/lib/src/types/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrganizationsListQueryVariables = Types.Exact<{
  parentOnly?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type OrganizationsListQuery = { organizations: Array<{ __typename: 'Organization', ID: CustomScalars.GQLUUID, name: string, lastPatientCreatedAt: CustomScalars.GQLDateTime | null, parent: { __typename: 'Organization', ID: CustomScalars.GQLUUID } | null, children: Array<{ __typename: 'Organization', ID: CustomScalars.GQLUUID, name: string, lastPatientCreatedAt: CustomScalars.GQLDateTime | null, stats: { __typename: 'OrganizationStats', registrarCount: number, practitionerCount: number, patientCount: number } }>, stats: { __typename: 'OrganizationStats', registrarCount: number, practitionerCount: number, patientCount: number } }> | null };


export const OrganizationsListDocument = gql`
    query OrganizationsList($parentOnly: Boolean) {
  organizations(parentOnly: $parentOnly) {
    __typename
    ID
    name
    lastPatientCreatedAt
    parent {
      __typename
      ID
    }
    children {
      __typename
      ID
      name
      stats {
        __typename
        registrarCount
        practitionerCount
        patientCount
      }
      lastPatientCreatedAt
    }
    stats {
      __typename
      registrarCount
      practitionerCount
      patientCount
    }
  }
}
    `;

/**
 * __useOrganizationsListQuery__
 *
 * To run a query within a React component, call `useOrganizationsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationsListQuery({
 *   variables: {
 *      parentOnly: // value for 'parentOnly'
 *   },
 * });
 */
export function useOrganizationsListQuery(baseOptions?: Apollo.QueryHookOptions<OrganizationsListQuery, OrganizationsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationsListQuery, OrganizationsListQueryVariables>(OrganizationsListDocument, options);
      }
export function useOrganizationsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationsListQuery, OrganizationsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationsListQuery, OrganizationsListQueryVariables>(OrganizationsListDocument, options);
        }
export type OrganizationsListQueryHookResult = ReturnType<typeof useOrganizationsListQuery>;
export type OrganizationsListLazyQueryHookResult = ReturnType<typeof useOrganizationsListLazyQuery>;

export function queryOrganizationsList(client: Apollo.ApolloClient<unknown>, variables?: OrganizationsListQueryVariables | undefined, options?: Omit<Apollo.QueryOptions<OrganizationsListQuery, OrganizationsListQueryVariables>, "query" | 'variables'>) {
  return client.query<OrganizationsListQuery, OrganizationsListQueryVariables>({
    query: OrganizationsListDocument,
    variables,
    ...options,
  });
}
