// This file is automatically generated. See gqlcodegen.yml for details
// prettier-ignore
// @ts-ignore
import * as CustomScalars from "@oui/lib/src/types/scalars";
import * as Types from '@oui/lib/src/types/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PatientsListQueryVariables = Types.Exact<{
  searchPattern?: Types.InputMaybe<Types.Scalars['String']['input']>;
  withOrganizationID?: Types.InputMaybe<Types.Scalars['UUID']['input']>;
}>;


export type PatientsListQuery = { patients: Array<{ __typename: 'PatientProfile', lastLogin: CustomScalars.GQLDateTime | null, patient: { __typename: 'Patient', ID: CustomScalars.GQLUUID, productVariant: Types.ProductVariant, productVersion: Types.ProductVersion, productStatic: boolean, onboardingVariant: Types.OnboardingVariant, person: { givenName: string | null, familyName: string | null } }, careTeam: { primaryPractitioner: { __typename: 'Practitioner', ID: CustomScalars.GQLUUID, person: { givenName: string | null, familyName: string | null } } | null }, progress: Array<{ content: Types.ContentType, completion: number, completed: boolean, updatedAt: CustomScalars.GQLDateTime }> }> };


export const PatientsListDocument = gql`
    query PatientsList($searchPattern: String, $withOrganizationID: UUID) {
  patients(
    searchPattern: $searchPattern
    withOrganizationID: $withOrganizationID
  ) {
    __typename
    patient {
      __typename
      ID
      person {
        givenName
        familyName
      }
      productVariant
      productVersion
      productStatic
      onboardingVariant
    }
    careTeam {
      primaryPractitioner {
        __typename
        ID
        person {
          givenName
          familyName
        }
      }
    }
    progress {
      content
      completion
      completed
      updatedAt
    }
    lastLogin
  }
}
    `;

/**
 * __usePatientsListQuery__
 *
 * To run a query within a React component, call `usePatientsListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientsListQuery({
 *   variables: {
 *      searchPattern: // value for 'searchPattern'
 *      withOrganizationID: // value for 'withOrganizationID'
 *   },
 * });
 */
export function usePatientsListQuery(baseOptions?: Apollo.QueryHookOptions<PatientsListQuery, PatientsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PatientsListQuery, PatientsListQueryVariables>(PatientsListDocument, options);
      }
export function usePatientsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PatientsListQuery, PatientsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PatientsListQuery, PatientsListQueryVariables>(PatientsListDocument, options);
        }
export type PatientsListQueryHookResult = ReturnType<typeof usePatientsListQuery>;
export type PatientsListLazyQueryHookResult = ReturnType<typeof usePatientsListLazyQuery>;

export function queryPatientsList(client: Apollo.ApolloClient<unknown>, variables?: PatientsListQueryVariables | undefined, options?: Omit<Apollo.QueryOptions<PatientsListQuery, PatientsListQueryVariables>, "query" | 'variables'>) {
  return client.query<PatientsListQuery, PatientsListQueryVariables>({
    query: PatientsListDocument,
    variables,
    ...options,
  });
}
